import React, { Component } from "react";
import classNames from "classnames";
import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";

class CustomStep extends Component {
  render() {
    const { classes, label, last, disabled } = this.props;

    return (
      <Grid
        item
        // direction="column"
        align="center"
        // justify="center"
        className={classNames(classes.root)}
      >
        <Grid item md={2} align="center" className={classNames(classes.labelHolder)}>
          <div
            className={classNames(classes.stepLabel, disabled ? classes.stepLabelDisabled : classes.stepLabelActive)}
          >
            <p>{label}</p>
          </div>
        </Grid>
        {!last && (
          <Grid item md={10} align="center" className={classNames(classes.sub)}>
            <div className={classNames(classes.vertLine)} />
          </Grid>
        )}
      </Grid>
    );
  }
}

const styles = theme => ({
  root: {
    height: "100%",
    overflow: "hidden"
  },
  labelHolder: {
    paddingTop: 16,
    maxWidth: "1000px"
  },
  sub: {
    paddingTop: 31,
    height: "100%"
  },
  stepLabel: {
    width: 20,
    height: 20,
    padding: 2,
    fontSize: "0.8em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 1000
  },
  stepLabelActive: {
    backgroundColor: `${theme.colors.primary.red}`,
    color: "white"
  },
  stepLabelDisabled: {
    backgroundColor: `${theme.colors.primary.darkGrey}`,
    color: "white"
  },
  vertLine: {
    borderLeft: `1px solid ${theme.colors.blackScale.black80}`,
    width: 1,
    height: "100%"
  }
});

export default withStyles(styles, { withTheme: true })(CustomStep);
