import * as actionTypes from "../types";
import ClaimExpenseService from "../../services/HR/claimexpense.service";
import util from "../../utils/Util"

export const claimExpenseActions = {
  errorPopup,
  closePopup,
  clearOnExit,
  getClaimTypes,
  getClaimExpenses,
  exportClaimExpenses
};

function beginApiCall() {
  return { type: actionTypes.HR_CLAIM_EXPENSE_SHOW_LOADING };
}

function endApiCall() {
  return { type: actionTypes.HR_CLAIM_EXPENSE_CLOSE_LOADING };
}

function successPopup(message) {
  return {
    type: actionTypes.HR_CLAIM_EXPENSE_SHOW_POPUP_SUCCESS,
    message
  };
}

function errorPopup(message) {
  return {
    type: actionTypes.HR_CLAIM_EXPENSE_SHOW_POPUP_ERROR,
    message
  };
}

function closePopup() {
  return { type: actionTypes.HR_CLAIM_EXPENSE_CLOSE_POPUP };
}

function clearOnExit() {
  return { type: actionTypes.HR_CLAIM_EXPENSE_CLEAR };
}

function getClaimTypesSuccess(data) {
  return {
    type: actionTypes.HR_CLAIM_EXPENSE_GET_CLAIM_TYPES_SUCCESS,
    data
  };
}

function getClaimExpensesSuccess(data, filter) {
  return {
    type: actionTypes.HR_CLAIM_EXPENSE_GET_CLAIM_EXPENSES_SUCCESS,
    data,
    filter
  };
}

function getClaimTypes(policyNo) {
  return function(dispatch) {
    dispatch(beginApiCall());
    return ClaimExpenseService.getClaimTypes(policyNo)
      .then(response => {
        if (response.success) {
          dispatch(endApiCall());
          dispatch(getClaimTypesSuccess(response.data));
        } else {
          throw response.data;
        }
      })
      .catch(error => {
        dispatch(endApiCall());
        dispatch(errorPopup("System error, please refresh the page."));
      });
  };
}

function getClaimExpenses(policyNo, companyId, filter) {
  return function(dispatch) {
    dispatch(beginApiCall());
    return ClaimExpenseService.getClaimExpenses(policyNo, companyId, filter)
      .then(response => {
        if (response.success) {
          dispatch(endApiCall());
          dispatch(getClaimExpensesSuccess(response.data, response.filter));
          dispatch(successPopup(`${response.data ? response.data.length : 0} claim expense records retrieved.`));
          return response.data;
        } else {
          throw response.data;
        }
      })
      .catch(error => {
        dispatch(endApiCall());
        dispatch(errorPopup(error || "System is busy, please try again."));
      });
  };
}

function exportClaimExpenses(policyNo, companyId, filter) {
  return function(dispatch) {
    dispatch(beginApiCall());
    return ClaimExpenseService.exportClaimExpenses(policyNo, companyId, filter)
      .then(response => {
        if (response.success && response.data) {
          util.downloadFile(response.data, response.filename || `Claim_Expenses_${policyNo}.xlsx`);
        }
        return response;
      })
      .then(response => {
        if (response.success) {
          dispatch(endApiCall());
          dispatch(successPopup("Claim expense report exported successfully."));
          return response.data;
        } else {
          throw response.data;
        }
      })
      .catch(error => {
        dispatch(endApiCall());
        dispatch(errorPopup("System is busy, please try again."));
      });
  };
}
