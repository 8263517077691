import React, { Component } from "react";
import { connect } from "react-redux";
import routes from "../../../../routes";
import BreadCrumbs from "../../../../components/BreadCrumbs";
import Enums from "../../../../utils/Enums";
import MyDocuments from "./MyDocuments";
import { renderRoutes } from "../../../../utils/Routes";

class Index extends Component {
  constructor(props) {
    super(props);
    this.HRRoutes = routes.HRRoutes.find(route => route.path === "/auth");
  }

  checkPCEAndPF3AreExistAtTheSameTime = () => {
    let result = [];
    if (this.props.clients) {
      for (const client of this.props.clients) {
        if (!result.includes(client.product_code)) {
          result.push(client.product_code);
        }
      }

      return (
        (result.includes("PCE") && (result.includes("PF3") || result.includes("PEP") || result.includes("PTF"))) ||
        (result.includes("GCP") && (result.includes("PF3") || result.includes("PTF") || result.includes("PEP")))
      );
    }

    return false;
  };

  render() {
    const isPCEAndPF3ExistAtTheSameTime = this.checkPCEAndPF3AreExistAtTheSameTime();
    return (
      <>
        {this.props.clients && isPCEAndPF3ExistAtTheSameTime ? (
          <main>
            <BreadCrumbs currentRoutes={this.HRRoutes.routes[Enums.ROUTE.DOCUMENTS]} pageLabel="documents detail" />
            {renderRoutes(this.props.routes, { ...this.props })}
          </main>
        ) : (
          <main>
            <BreadCrumbs currentRoutes={this.HRRoutes.routes[Enums.ROUTE.DOCUMENTS]} />
            <MyDocuments {...this.props} />
          </main>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    HR: state.HR,
    user: state.user,
    clients: state.HR.clientsAndPolicyListing.clients
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
