import { bindActionCreators } from "redux";
import { companyActions, quoteActions, documentActions, amlClauseActions } from "../../../../../../actions";
import { connect } from "react-redux";

import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import TextFieldWithValidator from "../../../../../../form/presentational/TextFieldWithValidator";
import DatePickerWithValidator from "../../../../../../form/presentational/DatePickerWithValidator";
import SelectFieldWithValidator from "../../../../../../form/presentational/SelectFieldWithValidator";
import ReduxFormValidate from "../../../../../../utils/ReduxFormValidate";
import Util from "../../../../../../utils/Util";
import Enums from "../../../../../../utils/Enums";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import countries from "../../../../../../assets/data/countries";
import countryOfResidence from "../../../../../../assets/data/countryOfResidence";
import { useEffect } from "react";
import { Button, Radio } from "@mui/material";
import { Person } from "@mui/icons-material";
import moment from "moment";
import { Form, Field } from "react-final-form";

const DeclarationOfAmlClauseForm = props => {
  let { readOnly, quote, document, intl } = props;
  const [amlClauseAction, setAmlClauseAction] = useState(null);
  const formInputs = {
    relationship_to_policyholder: "",
    full_name: "",
    id_no: "",
    dob: "",
    nationality: "",
    country_of_residence: "",
    percentage_of_shareholding: ""
  };

  const formatDate = date => {
    return (date && moment(date, "DD/MM/YYYY")) || null;
  };

  const handleClauseAction = status => {
    setAmlClauseAction(status);
    props.amlClauseActions.setAmlClauseAction(status);
  };

  const [formFields, setFormFields] = useState([formInputs]);

  useEffect(() => {
    if (quote.data["specificIndividuals"].length > 0) {
      const formData = quote.data["specificIndividuals"];
      setFormFields([...formData]);
      if (quote.data.amlClauseAction !== null) {
        setAmlClauseAction(quote.data.amlClauseAction);
        setFormFields([...formData]);
      } else {
        const newRow = formInputs;
        setFormFields([newRow]);
      }
    } else {
      if (quote.data.amlClauseAction === undefined) {
        setAmlClauseAction(null);
      } else {
        setAmlClauseAction(quote.data.amlClauseAction);
      }
    }
  }, []);

  const addFormFields = () => {
    const newRow = formInputs;
    setFormFields([...formFields, newRow]);
    setEndPointObject();
  };

  let handleChange = (i, e, value, name) => {
    let newFormValues = [...formFields];
    newFormValues[i][name] = value;
    setFormFields(newFormValues);
    setEndPointObject();
  };

  let removeFormFields = i => {
    let newFormValues = [...formFields];
    newFormValues.splice(i, 1);
    setFormFields(newFormValues);
    setEndPointObject();
  };

  let setEndPointObject = () => {
    props.amlClauseActions.setAmlClauseFormDetails(formFields);
  };

  let calculatePercent = value => {
    if (isNaN(+value)) {
      return true;
    }
    let currentPercent = 0;
    formFields.forEach(element => {
      currentPercent += +element.percentage_of_shareholding;
    });
    return currentPercent > 100 ? true : false;
  };

  const percentValidate = {
    validateAll: value =>
      value && calculatePercent(value)
        ? "Shareholding percentage is number only field and must not be more than  100."
        : undefined
  };

  readOnly = !readOnly && document.hasSignedProposal ? true : readOnly;

  if (props.quote.stages !== "SUBMITTED" && props.quote.stages !== "INFORCE") {
    if (props.initialValues.agent_code !== props.user.username) {
      if (props.document.files.length > 0) {
        readOnly = false;
        props.documentActions.resetFiles();
        props.document.files.map(file => {
          if (file && file.file_type === Enums.FILE_TYPE.ACCEPTANCE_FILE_TYPE) {
            props.documentActions.deleteFile(props.quote.id, file.encrypted_id).then(() => {});
          }
        });
      }
    }
  }

  return (
    <div>
      <Typography>
        Is there any specific individual who owns&nbsp;
        <Typography sx={{ textDecoration: "underline" }} display="inline">
          <Box fontWeight="bold" display="inline">
            less than 25%
          </Box>
        </Typography>
        &nbsp;of the shares or ownership interest in the company&nbsp;
        <Typography sx={{ textDecoration: "underline" }} display="inline">
          <Box fontWeight="bold" display="inline">
            but exercises controls over the business
          </Box>
        </Typography>
        &nbsp;(meaning making executive, significant management decisions on behalf of the company, significant
        control/decision making power over the company's operations, policies and/or strategic direction) e.g. company's
        board of directors, board of directors of corporate shareholder owing the policyholder, person financing the
        policy, etc.?
      </Typography>
      <Form 
        onSubmit={() => {}}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
            <FormControlLabel
              control={<Radio checked={amlClauseAction} onChange={e => handleClauseAction(true)} />}
              label="Yes"
            />
              <FormControlLabel
                control={
                  <Radio checked={amlClauseAction !== null && !amlClauseAction} onChange={e => handleClauseAction(false)} />
                }
                label="No"
              />
              {amlClauseAction && (
                <Grid container spacing={"24px"}>
                  <Grid item xs={12} sm={12} style={{ marginBottom: "10px" }}>
                    <Typography>
                      <br />
                      If yes, please ensure that the details of the individual are declared below.
                    </Typography>
                    <Typography>
                      (If there are more than 1 individual,&nbsp;
                      <Typography sx={{ textDecoration: "underline" }} display="inline">
                        please furnish the following information accordingly for every individual
                      </Typography>
                      )
                    </Typography>
                  </Grid>
                  {formFields.map((element, index) => (
                    <Grid
                      key={index}
                      container
                      spacing={"5px"}
                      style={{
                        marginLeft: "20px",
                        border: "1px solid #d3d3d3",
                        borderRadius: "3px",
                        padding: "10px",
                        marginTop: "5px"
                      }}
                    >
                      <Grid item sm={12} style={{ fontWeight: 600 }}>
                        <Person /> Individual {index + 1}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          name={"relationship_to_policyholder" + index}
                          id={"relationship_to_policyholder" + index}
                          disabled={readOnly}
                          component={TextFieldWithValidator}
                          validate={ReduxFormValidate.maxLength100}
                          autoComplete="[off]"
                          label="Relationship to the policyholder"
                          type="text"
                          inputProps={{
                            maxLength: 100,
                            value: element.relationship_to_policyholder
                          }}
                          onChange={(event, newValue) => {
                            handleChange(index, event, newValue, "relationship_to_policyholder");
                            props.handleEdits();
                          }}
                        />
                        <Field
                          name={"full_name" + 1}
                          id={"full_name" + index}
                          disabled={readOnly}
                          component={TextFieldWithValidator}
                          autoComplete="[off]"
                          label="Full name"
                          type="text"
                          inputProps={{
                            maxLength: 100,
                            value: element.full_name || ""
                          }}
                          onChange={(event, newValue) => {
                            handleChange(index, event, newValue, "full_name");
                            props.handleEdits();
                          }}
                        />
                        <Field
                          name={"id_no" + index}
                          id={"id_no" + index}
                          disabled={readOnly}
                          component={TextFieldWithValidator}
                          validate={ReduxFormValidate.alphaNumeric}
                          autoComplete="[off]"
                          label="NRIC/Passport No."
                          type="text"
                          inputProps={{
                            maxLength: 100,
                            value: element.id_no || ""
                          }}
                          onChange={(event, newValue,) => {
                            handleChange(index, event, newValue, "id_no");
                            props.handleEdits();
                          }}
                        />
                        <Field
                          fullWidth
                          name={"dob" + index}
                          disabled={readOnly}
                          component={DatePickerWithValidator}
                          autoComplete="[off]"
                          label="Date of Birth"
                          type="text"
                          inputProps={{
                            maxLength: 100,
                            value: formatDate(element.dob)
                          }}
                          onChange={(event, newValue) => {
                            let dateValue = moment(newValue).format("DD/MM/YYYY");
                            handleChange(index, event, dateValue, "dob");
                            props.handleEdits();
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          fullWidth
                          disabled={readOnly}
                          name={"nationality" + index}
                          autoComplete="[off]"
                          isSelect={true}
                          component={SelectFieldWithValidator}
                          label="Nationality"
                          inputProps={{
                            value: element.nationality || ""
                          }}
                          onChange={(event, newValue) => {
                            handleChange(index, event, newValue, "nationality");
                            props.handleEdits();
                          }}
                          onBlur={e => {
                            e.preventDefault();
                          }}
                        >
                          {countries.map(country => (
                            <MenuItem value={country.value} key={country.value}>
                              {country.label}
                            </MenuItem>
                          ))}
                        </Field>
                        <Field
                          fullWidth
                          disabled={readOnly}
                          name={"country_of_residence" + index}
                          autoComplete="[off]"
                          isSelect={true}
                          component={SelectFieldWithValidator}
                          label="Country of Residence"
                          inputProps={{
                            value: element.country_of_residence || ""
                          }}
                          onChange={(event, newValue) => {
                            handleChange(index, event, newValue, "country_of_residence");
                            props.handleEdits();
                          }}
                          onBlur={e => {
                            e.preventDefault();
                          }}
                        >
                          {countryOfResidence.map(country => (
                            <MenuItem value={country} key={country}>
                              {country}
                            </MenuItem>
                          ))}
                        </Field>
                        <Field
                          name={"percentage_of_shareholding" + 1}
                          disabled={readOnly}
                          validate={percentValidate.validateAll}
                          component={TextFieldWithValidator}
                          autoComplete="[off]"
                          label="Percentage of Shareholding (ordinary shares)"
                          type="text"
                          inputProps={{
                            maxLength: 100,
                            value: element.percentage_of_shareholding || ""
                          }}
                          onChange={(event, newValue) => {
                            handleChange(index, event, newValue, "percentage_of_shareholding");
                            props.handleEdits();
                          }}
                        />
                        {formFields.length > 1 && (
                          <Button
                            color="primary"
                            variant="contained"
                            type="button"
                            style={{ marginTop: "15px" }}
                            onClick={() => removeFormFields(index)}
                          >
                            Remove Individual {index + 1}
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                  <div style={{ marginLeft: "28px" }}>
                    <Button
                      color="primary"
                      variant="contained"
                      type="button"
                      style={{ marginTop: "15px" }}
                      onClick={() => addFormFields()}
                    >
                      Add Individual
                    </Button>
                  </div>
                </Grid>
              )}
            </form>
          )
        }}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    initialValues: state.company && state.company.details && Util.trimObj(state.company.details),
    quote: state.quote,
    readOnly: state.quote.readOnly,
    natureOfBusinessList: state.document.natureOfBusiness,
    countriesList: state.document.countries,
    user: state.user,
    agentType: state.user.auth.pcode,
    document: state.document
  };
}

function mapDispatchToProps(dispatch) {
  return {
    companyActions: bindActionCreators(companyActions, dispatch),
    quoteActions: bindActionCreators(quoteActions, dispatch),
    documentActions: bindActionCreators(documentActions, dispatch),
    amlClauseActions: bindActionCreators(amlClauseActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeclarationOfAmlClauseForm);

