import plansCode from "../assets/data/plansCode";
import moment from "moment";
import Enums from "./Enums";
import PolicyCompDisabledConfig from "./PolicyCompDisabledConfig.js";
import CodeCompDisabledConfig from "./CodeCompDisabledConfig.js";
import { DomainValidate } from "./DomainValidator";
import CiamResponseCode from "./CiamResponseCode.js";
import genderValues from "../assets/data/genderValues.js";

const EMPLOYEE_FORMAT_DATE = "DD/MM/YYYY";
const EMAIL_PATTERN = new RegExp("^\\s*?(.+)@(.+?)\\s*$");
const POSITION = [1, 1, 2, 3, 5, 8, 13, 21];

class Util {
  static validateLogin(appName, errorCode) {
    let errorResponse = appName
      ? CiamResponseCode.A_SUSPEND_INACTIVE.description_sale
      : CiamResponseCode.A_SUSPEND_INACTIVE.description;

    let passwordPolicy = appName
      ? CiamResponseCode.NOT_MATCH_PASSWORD_POLICY.description_sales_login
      : CiamResponseCode.NOT_MATCH_PASSWORD_POLICY.description_nsales_login;

    let generalMessage = appName ? CiamResponseCode.GENERAL_MESSAGE_SALES : CiamResponseCode.GENERAL_MESSAGE;

    switch (errorCode) {
      case CiamResponseCode.A_SUSPEND_INACTIVE.in_active_code:
      case CiamResponseCode.A_SUSPEND_INACTIVE.suspend_code:
      case CiamResponseCode.A_SUSPEND_INACTIVE.pw_expired:
        return errorResponse;

      case CiamResponseCode.ACCOUNT_BLOCK.code:
        return CiamResponseCode.ACCOUNT_BLOCK.description;

      case CiamResponseCode.NOT_MATCH_PASSWORD_POLICY.code:
        return passwordPolicy;

      case CiamResponseCode.OTP_EXPIRED.code:
        return CiamResponseCode.OTP_EXPIRED.description;

      case CiamResponseCode.INCORRECT_PW.code:
      case CiamResponseCode.NOT_AUTHENTICATED:
      case CiamResponseCode.UNAUTHORIZED:
        return generalMessage;

      default:
        return generalMessage;
    }
  }
  static validateOtpLogin(errorCode) {
    switch (errorCode) {
      case CiamResponseCode.MAX_OTP_FAILED.code:
        return CiamResponseCode.MAX_OTP_FAILED.description;

      case CiamResponseCode.OTP_EXPIRED.code:
      case CiamResponseCode.OTP_EXPIRED.code_old:
        return CiamResponseCode.OTP_EXPIRED.description;

      case CiamResponseCode.RESETPW_OTP_EXPIRED.code:
        return CiamResponseCode.RESETPW_OTP_EXPIRED.description;

      case CiamResponseCode.FORGEROCK_ERROR.code:
        return CiamResponseCode.FORGEROCK_ERROR.description_otp;

      case CiamResponseCode.ACCOUNT_BLOCK.code:
        return CiamResponseCode.ACCOUNT_BLOCK.description;

      default:
        return CiamResponseCode.INVALID_OTP.description;
    }
  }

  static validateOtpResetPassword(errorCode) {
    switch (errorCode) {
      case CiamResponseCode.MAX_OTP_FAILED.code:
        return CiamResponseCode.MAX_OTP_FAILED.description_reset_password;

      case CiamResponseCode.OTP_EXPIRED.code:
      case CiamResponseCode.OTP_EXPIRED.code_old:
        return CiamResponseCode.OTP_EXPIRED.description_reset_password;

      case CiamResponseCode.RESETPW_OTP_EXPIRED.code:
        return CiamResponseCode.RESETPW_OTP_EXPIRED.description;

      case CiamResponseCode.FORGEROCK_ERROR.code:
        return CiamResponseCode.FORGEROCK_ERROR.description_reset_password;
      case CiamResponseCode.ACCOUNT_BLOCK.code:
        return CiamResponseCode.ACCOUNT_BLOCK.description_reset_password;

      case CiamResponseCode.NOT_MATCH_PASSWORD_POLICY.code:
        return CiamResponseCode.NOT_MATCH_PASSWORD_POLICY.description;

      default:
        return CiamResponseCode.INVALID_OTP.description;
    }
  }

  static errorCodeCloseOtpDialog(error) {
    console.log("util===============: " + error.fail);
    error.isCloseOtpDialog = true;
    switch (error.message) {
      case CiamResponseCode.NOT_MATCH_PASSWORD_POLICY.code:
        error.customMessage = CiamResponseCode.NOT_MATCH_PASSWORD_POLICY.description;
        break;
      case CiamResponseCode.ACCOUNT_LOCKED_FOR_WRONG_OTP_RESET_PWD.code:
        error.customMessage = CiamResponseCode.ACCOUNT_LOCKED_FOR_WRONG_OTP_RESET_PWD.description;
        break;
      case CiamResponseCode.MAX_OTP_FAILED.code:
        error.customMessage = CiamResponseCode.MAX_OTP_FAILED.description;
        break;
      case CiamResponseCode.EXCEEDED_OTP_REGENERATION.code:
        error.customMessage = CiamResponseCode.EXCEEDED_OTP_REGENERATION.description;
        break;
      case CiamResponseCode.FORGEROCK_ERROR.code:
        error.customMessage = CiamResponseCode.FORGEROCK_ERROR.description_reqOtp;
        break;
      case CiamResponseCode.OTP_EXPIRED.code:
      case CiamResponseCode.OTP_EXPIRED.code_old:
        error.customMessage = CiamResponseCode.OTP_EXPIRED.description;
        break;
      default:
        error.isCloseOtpDialog = false;
        break;
    }
  }

  static isPlanValid = false;

  static invalidFileName(coll, { name }) {
    let dialogDescription;

    const validCharRegex = /^[\-|\.| |\w|àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]*$/;
    const regex = new RegExp(validCharRegex);
    const maxLength = 100;

    if (name.length === 0) {
      dialogDescription = "Filename can't be empty.";
    } else if (name.length > maxLength) {
      dialogDescription = `Filename can't be over ${maxLength} characters.`;
    } else if (!regex.exec(name)) {
      dialogDescription = "Filename contains invalid characters. Please remove any special characters from file name.";
    }
    if (dialogDescription) {
      coll.push({ invalid: true, dialogDescription });
    }
    return coll;
  }

  static isEmail(value) {
    return (
      /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(
        value
      ) && Util.isValidDomain(value)
    );
  }

  static isEmailForbiddenDomainPatterns(value) {
    const forbiddenDomainPatterns = /(prufa\.com\.sg|pruadviser\.com\.sg)$/;
    return forbiddenDomainPatterns.test(value);
  }

  static isEmailForbiddenUsernamePatterns(value) {
    const forbiddenUsernamePatterns = /^(dummy|qwerty|abc$|123$)[a-zA-Z0-9._%+-]*@/;
    return forbiddenUsernamePatterns.test(value);
  }

  static foreignWorkerValidation(prevProps) {
    const allForeignWorkersY = prevProps?.quote?.data?.persons?.every(obj => obj.foreign_worker === "Y");

    return {
      allForeignWorkersY: allForeignWorkersY,
      isInvalid: prevProps?.quote?.waiting_period != 0 && allForeignWorkersY
    };
  }

  static validateUen(uen_no) {
    if (typeof uen_no !== "string" || uen_no.trim() === "") {
      return false;
    }
    const regex = /^[a-zA-Z0-9]+$/;
    return regex.test(uen_no);
  }

  static isNameValid(value) {
    const reg = new RegExp(this.nbstpFieldRegex);
    return reg.test(value);
  }

  static isDialogNbstpEnabled(dateToCheck, dialogAutoDisable) {
    if (dialogAutoDisable) {
      const checkDate = new Date(dateToCheck);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      checkDate.setHours(0, 0, 0, 0);

      return checkDate > today;
    }
    return false;
  }

  static compareDate(date1, date2) {
    const dateCompare1 = new Date(date1);
    const dateCompare2 = new Date(date2);

    dateCompare1.setHours(0, 0, 0, 0);
    dateCompare2.setHours(0, 0, 0, 0);

    return dateCompare1 < dateCompare2;
  }

  static isSubmitNbstpDisabled(signingDate, disableSubmitSignDate, dateStartDisabling) {
    const isStartDisableDate = new Date(dateStartDisabling);
    const checkSignDate = new Date(signingDate);
    const checkDate = new Date(disableSubmitSignDate);
    const today = new Date();

    today.setHours(0, 0, 0, 0);
    checkDate.setHours(0, 0, 0, 0);
    isStartDisableDate.setHours(0, 0, 0, 0);
    checkSignDate.setHours(0, 0, 0, 0);

    if (isStartDisableDate <= today) {
      return checkDate >= checkSignDate;
    }
    return false;
  }

  static convertEpochToDate(epochTime) {
    const date = new Date(epochTime);

    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  }

  static isNRICValid(value) {
    // only validate if the nationality is singapore
    // validation rules
    const nricRegex = /(\D)(\d{7})(\D)/;
    const nricTypeRegex = /S|T/;
    const weightArr = [2, 7, 6, 5, 4, 3, 2];
    const nricLetters = ["J", "Z", "I", "H", "G", "F", "E", "D", "C", "B", "A"];

    const nric = value.toUpperCase();

    if (!nricRegex.exec(nric)) {
      return false;
    }

    const nricArr = nric.match(nricRegex);
    const nricType = nricArr[1];

    if (!nricTypeRegex.exec(nricType)) {
      return false;
    }

    const nricDigitsArr = nricArr[2].split("");
    let total = 0;
    for (let i = 0; i < nricDigitsArr.length; i++) {
      total += parseInt(nricDigitsArr[i]) * weightArr[i];
    }

    if (["T"].indexOf(nricType) >= 0) {
      total += 4;
    }

    const letterIndex = total % 11;
    const nricLetter = nricArr[3];
    if (["S", "T"].indexOf(nricType) >= 0) {
      return nricLetters[letterIndex] === nricLetter;
    }
  }

  static isEmploymentStartDateValid(employmentStartDate, coverageStartDate) {
    if (employmentStartDate === null || coverageStartDate === null) {
      return true;
    } else {
      return (
        moment(employmentStartDate, EMPLOYEE_FORMAT_DATE)
          .toDate()
          .valueOf() <=
        moment(coverageStartDate, EMPLOYEE_FORMAT_DATE)
          .toDate()
          .valueOf()
      );
    }
  }

  static foreignWorkerValidation(prevProps) {
    const allForeignWorkersY = prevProps?.quote?.data?.persons?.every(obj => obj.foreign_worker === "Y");

    return {
      allForeignWorkersY: allForeignWorkersY,
      isInvalid: prevProps?.quote?.waiting_period != 0 && allForeignWorkersY
    };
  }

  static validateUen(uen_no) {
    if (typeof uen_no !== "string" || uen_no.trim() === "") {
      return false;
    }

    const regex = /^[a-zA-Z0-9]+$/;

    return regex.test(uen_no);
  }

  static isValidDomain(value) {
    var groups = value.match(EMAIL_PATTERN);
    if (!groups) {
      console.log("is valid group false");
      return false;
    }
    return DomainValidate.isValid(groups[2]);
  }
  static isEmpty(obj) {
    if (typeof obj === "undefined" || obj === null) return true;
    if (typeof obj === "object") {
      if (Array.isArray(obj)) {
        return obj.length === 0;
      } else {
        for (var key in obj) {
          if (obj.hasOwnProperty(key)) return false;
        }
        return true;
      }
    }
    if (typeof obj === "string" && obj === "") return true;
    return false;
  }
  /**
   * We need to detect if user is using HR
   * @HR show the contact_name
   * It may have more cases in future. Currently just return empty string if it doesn't fit the condition
   * @return Name of user (string)
   */
  static showUserName(userState, appType) {
    switch (appType) {
      case Enums.APP_TYPE.HR:
        //return userState.fullName;
        return userState.hrInformation.contact_name;
      default:
        return "";
    }
  }

  static hasEmptyValues(obj) {
    let isEmpty = true;
    Object.keys(obj).forEach(key => {
      if (!Util.isEmpty(obj[key])) {
        isEmpty = false;
      }
    });
    return isEmpty;
  }

  static generateUUID() {
    var d = new Date().getTime();
    if (typeof performance !== "undefined" && typeof performance.now === "function") {
      d += performance.now(); //use high-precision timer if available
    }
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
  }

  static parseInteger(value) {
    try {
      return parseInt(value, 10);
    } catch (ex) {
      return null;
    }
  }

  static trimObj(obj) {
    if (this.isEmpty(obj) || (!Array.isArray(obj) && typeof obj !== "object")) return obj;
    return Object.keys(obj).reduce(
      function(acc, key) {
        acc[key.trim()] = typeof obj[key] === "string" ? obj[key].trim() : Util.trimObj(obj[key]);
        return acc;
      },
      Array.isArray(obj) ? [] : {}
    );
  }

  static getNextMonth() {
    let now = new Date();
    let current = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    if (now.getMonth() === 11) current = new Date(now.getFullYear() + 1, 0, 1);

    return current;
  }

  static async forEachAsync(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  static removeArrayValue(arrs, objValue) {
    if (!Array.isArray(arrs) && typeof arrs !== "object") return arrs;
    let index = arrs.indexOf(objValue);
    if (index !== -1) {
      arrs.splice(index, 1);
    }
  }

  static putUnique(arrs, objValue) {
    if (!Array.isArray(arrs) && typeof arrs !== "object") return arrs;
    let index = arrs.indexOf(objValue);
    if (index === -1) arrs.push(objValue);
  }

  static clearArray(arr) {
    while (arr.length > 0) {
      arr.pop();
    }
    return arr;
  }

  static jwt_decode(encoded) {
    const base64Url = encoded.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  static sortAsc(obj) {
    return obj.sort((a, b) => a - b);
  }

  static uniqueList(obj) {
    return obj.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
  }

  static clone(obj) {
    if (this.isEmpty(obj)) return null;
    return JSON.parse(this.stringify(obj));
  }

  static cloneOrigin(obj) {
    if (obj === null) return null;
    return JSON.parse(this.stringify(obj));
  }

  static contains(obj, value) {
    if (obj) {
      return obj.indexOf(value) !== -1;
    }
  }

  static caseInsensitiveEqual(first, second) {
    return first && second ? first.toLowerCase() === second.toLowerCase() : false;
  }

  static debounce(delay, fn) {
    let timerId;
    return function(...args) {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => {
        fn(...args);
        timerId = null;
      }, delay);
    };
  }

  static throttle(delay, fn) {
    let lastCall = 0;
    return function(...args) {
      const now = new Date().getTime();
      if (now - lastCall < delay) {
        return;
      }
      lastCall = now;
      return fn(...args);
    };
  }

  static mapPlansCodeLabel(msg) {
    plansCode.forEach(p => {
      msg = Util.contains(msg, p.value) ? msg.replace(p.value, p.label) : msg;
    });
    return msg;
  }

  static decodeAccessToken(token) {
    const decoded = this.jwt_decode(token);
    return {
      expire_time: moment.unix(decoded.exp).toDate(),
      role: decoded.ar,
      pid: decoded.pid,
      pcode: decoded.pcode || null,
      chncode: decoded.chncode,
      scope: decoded.scope.join(" "),
      agentname: decoded.agentname,
      agentgender: decoded.agentgender,
      // whitelist by module
      wbm: decoded.wbm,
      // accessible modules
      ams: decoded.ams
    };
  }
  static isValidDateFormat(date) {
    return moment(date, EMPLOYEE_FORMAT_DATE, true).isValid();
  }

  static stringify(obj, replacer, spaces, cycleReplacer) {
    return JSON.stringify(obj, this.serializer(replacer, cycleReplacer), spaces);
  }

  static serializer(replacer, cycleReplacer) {
    var stack = [],
      keys = [];

    if (cycleReplacer == null)
      cycleReplacer = function(key, value) {
        return null;
      };

    return function(key, value) {
      if (stack.length > 0) {
        var thisPos = stack.indexOf(this);
        ~thisPos ? stack.splice(thisPos + 1) : stack.push(this);
        ~thisPos ? keys.splice(thisPos, Infinity, key) : keys.push(key);
        if (~stack.indexOf(value)) value = cycleReplacer.call(this, key, value);
      } else stack.push(value);

      return replacer == null ? value : replacer.call(this, key, value);
    };
  }

  static getSecret(key) {
    return POSITION.map(i => key[i]).join("");
  }

  static async getCHash(key, cnonce) {
    const encoder = new TextEncoder();
    const salt = this.getSecret(key);
    const data = encoder.encode(salt + cnonce);

    try {
      // Generate the SHA-256 hash
      const hashBuffer = await window.crypto.subtle.digest("SHA-256", data);
      // Convert the hash to a Base64 string
      return btoa(String.fromCharCode(...new Uint8Array(hashBuffer)));
    } catch (error) {
      console.error("Error generating hash:", error);
      return null;
    }
  }

  static getEnvFromHostname() {
    const hostname = window.location.hostname;
    let env = "PROD";
    if (hostname.includes("local") || hostname.includes("LOCAL")) {
      env = "LOCAL";
    } else if (hostname.includes("sit") || hostname.includes("SIT")) {
      env = "SIT";
    } else if (hostname.includes("uat") || hostname.includes("UAT")) {
      env = "UAT";
    }
    return env;
  }

  static isCompDisabled(policy_no, comp) {
    const env_comp_map = PolicyCompDisabledConfig[this.getEnvFromHostname()];
    return _.includes(env_comp_map[policy_no], comp);
  }

  static isCompDisabledForCode(product_code, comp_name) {
    const code_comp_list = CodeCompDisabledConfig[product_code];
    return _.includes(code_comp_list, comp_name);
  }

  static isProductPF3(productCode) {
    return Enums.PRODUCT_CODE_PF3 == productCode;
  }

  static nbstpFieldRegex = "^[a-zA-Z0-9-()&#%.@+/:$*!? '\\\\]+$";

  static nbstpFieldRegexCompanyname = "^[a-zA-Z0-9-()&#%.@+/:$*!? '\\\\]+$";

  static nbstpFieldRegexCompanyContactname = "^[a-zA-Z0-9-()&#%.@+/:$*!? '\\\\]+$";

  static isGFWM(planCode) {
    return Enums.PLAN_CODE_GFWM == planCode.substring(0, Enums.PLAN_CODE_GFWM.length);
  }
  static isAlphaNumeric(value) {
    let meRegex = /[^\w]|_/g;
    let isInvalid;
    isInvalid = meRegex.test(value);
    if (isInvalid) {
      return false;
    } else {
      return true;
    }
  }
  static getAgentName(agentname, agentgender) {
    if (agentname?.trim() && agentgender?.trim()) {
      const title = genderValues.find(option => option.value === agentgender)?.title || "";
      return `${title} ${agentname}`;
    } else {
      return "";
    }
  }
  static downloadFile(data, fileName, mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
    const blob = new Blob([data], { type: mimeType });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  }
  static async hashSHA512(input) {
    const encoder = new TextEncoder();
    const data = encoder.encode(input);
    const hashBuffer = await crypto.subtle.digest("SHA-512", data);
    return Array.from(new Uint8Array(hashBuffer))
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");
  }
  static mobile() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isPhone = /iPhone|Android.*Mobile|Windows Phone/i.test(userAgent);

    return isPhone ? true : false;
  }
}

export default Util;
