import { useEffect, useMemo, useState } from "react";
import SimpleControlDialog from "../../../../../components/SimpleControlDialog";
import { renderTextField } from "../../../../../components/CustomReduxFormComponent";
import Typography from "@mui/material/Typography";
import useOtp from "../../hooks/useOtp";
import { typographyRootStyle } from "../../types/typographyRootStyle";
import { Form, Field } from "react-final-form";

const OtpSms = ({
  txId,
  show,
  description,
  closeHandler,
  submitHandler,
  submitError,
  submitErrorMessage,
  anotherWayHandler,
  closeButtonDisabled
}) => {
  let formApi;
  const classes = useMemo(() => ({
    title: {
      paddingBottom: "1rem",
      fontWeight: "500"
    }
  }), []);
  
  useEffect(() => {
    formApi?.reset();
  }, [show, formApi]);

  const submit = values => {
    submitHandler(values.otp);
  };

  const { otpState, sendOtp } = useOtp();

  useEffect(() => {
    if (show) {
      console.log("otpState", otpState);
      if (otpState?.previousOtpMethod !== "SMS") {
        console.log("otpState", otpState);
        console.log("SENDING SMS@@@");
        sendOtp("SMS", txId);
      }
    }
  }, [show]);

  return (
    <>
      <Form 
        onSubmit={submit}
        render={({ handleSubmit, valid, form }) => {
          formApi = form;
          return (
            <SimpleControlDialog
              show={show}
              title="Enter OTP"
              description={description}
              onClose={closeHandler}
              closeHandler={anotherWayHandler}
              valid={valid}
              onSubmit={handleSubmit}
              okButtonText="Submit"
              closeButtonText="Try Another Way"
              showCloseButton={true}
              closeButtonDisabled={closeButtonDisabled}
            >
              {otpState?.errorSms && (
                <div variant="caption" style={typographyRootStyle}>
                  {otpState?.errorSms}
                </div>
              )}
              {!otpState?.errorSms && otpState?.sentChannel === "SMS" && (
                <Typography variant="body2" className={classes.title}>
                  A OTP was just sent to your mobile phone.
                </Typography>
              )}
              <Field
                name="otp"
                autoFocus
                margin="dense"
                component={renderTextField}
                autoComplete="off"
                label="OTP"
                type="text"
              />
              {submitError && (
                <div variant="caption" style={typographyRootStyle}>
                  {submitErrorMessage}
                </div>
              )}
            </SimpleControlDialog>
          )
        }}
      />
    </>
  );
};

export default OtpSms;
