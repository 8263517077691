import React, { Component } from "react";
import { connect } from "react-redux";
import routes from "../../../../routes";
import BreadCrumbs from "../../../../components/BreadCrumbs";
import Enums from "../../../../utils/Enums";
import { renderRoutes } from "../../../../utils/Routes";

class ClaimExpenses extends Component {
  constructor(props) {
    super(props);
    this.HRRoutes = routes.HRRoutes.find(route => route.path === "/auth");
  }

  render() {
    return (
      <main>
        <BreadCrumbs
          currentRoutes={this.HRRoutes.routes[Enums.ROUTE.CLAIM_EXPENSES]}
          pageLabel="claim expenses detail"
        />
        {renderRoutes(this.props.routes)}
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimExpenses);
