import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import assets from "../../../../assets";
import WatchVideo from "../../components/watchVideo";
import DOMPurify from "dompurify";

class OfferInfo extends Component {
  render() {
    const { classes, productCategory, productIndex, productInfo } = this.props;
    const { introImages } = assets;
    const data = productInfo.products[productCategory] && productInfo.products[productCategory][productIndex];

    return (
      <div className={classes.root}>
        <Grid container spacing={"40px"} alignItems="center" className={classes.infoHolder}>
          <Grid className={classes.imgHolder} item sm={12} md={6}>
            <img className={classes[data.className]} alt="Overview" src={introImages[data.image]} />
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <p className={classes.header}>{data.header}</p>
              {data.isVideo && <WatchVideo textAlign="left" youTubeID={data.youTubeID} />}
              <p className={classes.desc} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.desc) }} />
              <p className={classes.staticPartnership}>In partnership with:</p>
              <div className={classes.logoHolder}>
                {data.logo.map((img, index) => {
                  return (
                    <img
                      key={index}
                      className={classes.logo}
                      alt="logo"
                      height={data.imageHeight ? data.imageHeight(index) : "30px"}
                      src={img}
                    />
                  );
                })}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    background: "white",
    paddingBottom: "50px"
  },
  infoHolder: {
    [theme.breakpoints.up("md")]: {
      margin: "0px 50px"
    },
    [theme.breakpoints.down("md")]: {
      margin: "0px 50px"
    },
    width: "auto",
    border: "1px solid #e7e7e7",
    boxShadow: "0 4px 2px -2px #e7e7e7",
    padding: "20px"
  },
  imgHolder: {
    textAlign: "center"
  },
  imgHorizontal: {
    width: "100%"
  },
  imgVertical: {
    [theme.breakpoints.up("md")]: {
      width: "28%"
    },
    [theme.breakpoints.down("md")]: {
      width: "50%"
    }
  },
  logoHolder: {
    display: "flex",
    alignItems: "center"
  },
  header: {
    fontSize: "24px"
  },
  desc: {
    fontSize: "16px",
    lineHeight: "30px",
    textAlign: "justify",
    paddingBottom: "30px"
  },
  staticPartnership: {
    color: "#8E8E8E",
    fontSize: "12px"
  },
  logo: {
    marginRight: "30px"
  }
});

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(OfferInfo));
