import { Form } from 'react-final-form';
import React from "react";
import SimpleControlDialog from "../../../../components/SimpleControlDialog";

class DeleteQuoteDialog extends React.Component {
  submit = () => {
    this.props.submitHandler();
  };
  render() {
    return (
      <>
        <Form
          onSubmit={this.submit}
          render={({ handleSubmit }) => {
            return (
              <>
                <SimpleControlDialog
                  show={this.props.show}
                  title="Confirm Delete Quotation"
                  description="Are you sure you want to delete your Draft Quotation?"
                  onClose={this.props.closeHandler}
                  closeHandler={this.props.closeHandler}
                  valid={true}
                  onSubmit={handleSubmit}
                  okButtonText="Yes"
                  closeButtonText="No"
                />    
              </>
            )
          }}
        />
      </>
    );
  }
}
export default DeleteQuoteDialog;
