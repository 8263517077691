import React from "react";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import classNames from "classnames";

export const renderTextField = ({ 
  input, 
  label, 
  meta: { error, warning, touched }, 
  isSelect, 
  children,
  onChange, 
  ...custom 
}) => {
  return (
    <div>
      <TextField
        margin="normal"
        fullWidth
        select={isSelect || false}
        error={touched && error !== undefined}
        label={label}
        {...input}
        {...custom}
        onChange={(e) => {
          input.onChange(e);
          if (onChange) {
            onChange(e, e.target.value, input.value, input.name);
          }
        }}
      >
        {children}
      </TextField>
      {touched && (
        <>
          {error && (
            <FormHelperText error={true} className={classNames({ "m-b-10": error })}>
              {error}
            </FormHelperText>
          )}
          {warning && (
            <FormHelperText error={true} className={classNames({ "m-b-10": warning })}>
              {warning}
            </FormHelperText>
          )}
        </>
      )}
    </div>
  );
};