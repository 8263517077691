import React, { Component } from "react";
import { connect } from "react-redux";
import routes from "../../../../routes";
import BreadCrumbs from "../../../../components/BreadCrumbs";
import Enums from "../../../../utils/Enums";
import { renderRoutes } from "../../../../utils/Routes";

class MyEmployees extends Component {
  constructor(props) {
    super(props);
    this.HRRoutes = routes.HRRoutes.find(route => route.path === "/auth");
  }

  render() {
    const { navDrawerOpen } = this.props;
    return (
      <main>
        <BreadCrumbs currentRoutes={this.HRRoutes.routes[Enums.ROUTE.MY_EMPLOYEES]} pageLabel="my employees list" />
        {renderRoutes(this.props.routes, { navDrawerOpen })}
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(MyEmployees);
