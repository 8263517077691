import "./EmployeeAdd.css";
import { AddCircle as AddCircleIcon } from "@mui/icons-material";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import classNames from "classnames";
import EmployeeDropZone from "./EmployeeDropZone";
import Typography from "@mui/material/Typography";
import DOMPurify from "dompurify";
import React, { Component } from "react";
import SnackBar from "../../../../../../components/SnackBar";
import Enums from "../../../../../../utils/Enums";
import config from "../../../../../../config/config";

class EmployeeAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSnackBarError: false,
      disableButton: true,
      pdpaConsent: false,
      prefillMemberButton: true,
      agentTitle: ""
    };
  }

  getErrors = () => {
    let error = null;
    let showErrorMsg = this.props.employee.uploadErrorCode > 0 && this.props.employee.uploadErrorCode !== 500;

    if (this.props.employee.uploadErrorCode === 500) {
      error = {
        title: this.props.intl.formatMessage({
          id: "validator.file.uploadError.text"
        }),
        msg: this.props.intl.formatMessage({
          id: "validator.file.uploadError.description"
        })
      };
    } else if (this.props.employee.uploadErrorMsg) {
      error = {
        title: "",
        msg: this.props.employee.uploadErrorMsg || "An error occured while uploading the file, please try again later."
      };
    }

    return showErrorMsg ? error : null;
  };

  componentWillReceiveProps(nextProps) {
    let showSnackBarError = nextProps.employee.uploadErrorCode === 400;
    this.setState({ showSnackBarError: showSnackBarError });
  }

  handleCloseSnackbarError = () => {
    this.setState({ showSnackBarError: false });
  };

  checkPDPA = event => {
    if (event.target.checked == true && this.props.business_type == Enums.BUSINESS_TYPE.CONVERSION) {
      this.setState({
        pdpaConsent: true,
        prefillMemberButton: false
      });
    } else {
      this.setState({
        pdpaConsent: false,
        prefillMemberButton: true
      });
    }
  };

  prefillMembers = () => {
    this.props.prefill(true);
  };

  getAgentTitle = chncode => {
    let title = "Financial Consultant";
    switch (chncode) {
      //PD (SCB,UOB) , AD , PFA
      case Enums.CHN_CODE.PD:
        title = "Insurance Specialist";
        break;
      case Enums.CHN_CODE.AD:
        title = "Financial Consultant";
        break;
      case Enums.CHN_CODE.PFA:
        title = "Wealth Manager";
        break;
      case Enums.CHN_CODE.BRO:
        title = "Consultant";
        break;
      default:
        title = "Financial Consultant";
        break;
    }

    return title;
  };

  componentWillMount() {
    const title = this.getAgentTitle(this.props.user.auth.chncode);

    this.setState({
      agentTitle: title
    });
  }

  render() {
    const { classes, quote, handlerAddOneByOne, prefill, pdpa_consent, handlerPDPA, intl } = this.props;
    // const pdpa_consent = this.state.pdpaConsent;
    return (
      <div className={classNames(classes.root, !pdpa_consent && classes.disabledClass)}>
        <Typography component="p" className={classes.pdpaTitle}>
          {intl.formatMessage({ id: "employee.PDPATitle" })}
        </Typography>
        <FormControlLabel
          className={classes.checkBoxAndTextWrapper}
          classes={{ root: classes.rootCheckBoxAndTextWrapperCustomize }}
          control={
            <Checkbox
              className={classes.checkBoxPDPA}
              checked={pdpa_consent}
              //checked={this.props.business_type == Enums.BUSINESS_TYPE.CONVERSION ?  this.state.pdpaConsent : pdpa_consent}
              //onChange={this.props.business_type == Enums.BUSINESS_TYPE.CONVERSION ? this.checkPDPA : handlerPDPA}
              onChange={handlerPDPA}
              color="primary"
              value="checkedA"
            />
          }
          label={
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(intl.formatMessage(
                  { id: "employee.PDPAText" },
                  {
                    NRIC: this.props.user.username,
                    SME: this.props.company.details.name,
                    AgentTitle: this.state.agentTitle
                  }
                ))
              }} 
            />
          }
        />
        {(this.props.business_type == Enums.BUSINESS_TYPE.NEW ||
          (this.props.business_type === "CONVERSION" && config.disable_conversion)) && (
          <div>
            <EmployeeDropZone />
            {this.state.showSnackBarError && (
              <SnackBar
                type="error"
                open={this.state.showSnackBarError}
                onClose={this.handleCloseSnackbarError}
                message="The file is not in a recognized format. Please make sure you are using the correct employee template."
              />
            )}

            <h1 className={classNames(classes.or)}> or </h1>

            <Button
              className={classes.button}
              disabled={!pdpa_consent}
              onClick={handlerAddOneByOne}
              variant="contained"
              color="primary"
            >
              <AddCircleIcon className={classes.leftIcon} />
              <span>{intl.formatMessage({ id: "employee.addButton" })}</span>
            </Button>
          </div>
        )}

        {this.props.business_type == Enums.BUSINESS_TYPE.CONVERSION && !config.disable_conversion && (
          <div>
            <br />
            <Button
              className={classes.button}
              disabled={!pdpa_consent}
              //disabled={this.state.prefillMemberButton}
              //onClick={this.prefillMembers}
              onClick={prefill}
              variant="raised"
              color="primary"
            >
              <AddCircleIcon className={classes.leftIcon} />
              <span>{intl.formatMessage({ id: "employee.prefillMembersButton" })}</span>
            </Button>

            <h1 className={classNames(classes.or)}> or </h1>

            <Button
              className={classes.button}
              disabled={!pdpa_consent}
              onClick={handlerAddOneByOne}
              variant="raised"
              color="primary"
            >
              <AddCircleIcon className={classes.leftIcon} />
              <span>{intl.formatMessage({ id: "employee.addButton" })}</span>
            </Button>
          </div>
        )}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    margin: theme.spacing.unit * 2,
    textAlign: "center",
    width: "100%"
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  button: {
    margin: theme.spacing.unit
  },
  errorWrapper: {
    margin: "0 20%"
  },
  errorContainer: {
    padding: 10,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 2,
    backgroundColor: "rgba(255,0,0,0.1)",
    marginBottom: 20
  },
  errorTitle: {
    color: theme.palette.primary.darken
  },
  or: {
    overflow: "hidden",
    textAlign: "center",
    "&:before": {
      right: "1em",
      marginLeft: "-50%"
    },
    "&:after": {
      left: "1em",
      marginRight: "-50%"
    },
    "&:before, &:after": {
      backgroundColor: theme.palette.common.black,
      content: '""',
      display: "inline-block",
      height: "1px",
      position: "relative",
      verticalAlign: "middle",
      width: "10%"
    }
  },
  disabledClass: {
    "& $or": {
      color: `${theme.palette.text.disabled} !important`,
      "&:before, &:after": {
        backgroundColor: theme.palette.action.disabled
      }
    }
  },
  checkBoxAndTextWrapper: {
    textAlign: "justify"
  },
  rootCheckBoxAndTextWrapperCustomize: {
    alignItems: "flex-start"
  },
  checkBoxPDPA: {
    alignItems: "flex-start"
  },
  pdpaTitle: {
    paddingBottom: 30,
    fontSize: 20,
    fontWeight: 700
  }
});

function mapStateToProps(state) {
  return {
    employee: state.quote.employee,
    quote: state.quote,
    user: state.user,
    company: state.company,
    business_type: state.quote.business_type
  };
}

export default connect(mapStateToProps)(injectIntl(withStyles(styles, { withTheme: true })(EmployeeAdd)));
