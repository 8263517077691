import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import { renderRoutes } from "../../../../utils/Routes";

class EmployeeBenefit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listBenefits: []
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <main>{renderRoutes(this.props.routes)}</main>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    height: "100%"
  },
  menuItem: {
    textDecoration: "none"
  },
  content: {
    padding: theme.spacing.unit * 2.5,
    paddingTop: 10
  },
  listBenefits: {
    flexGrow: 1
  },
  wrapper: {
    padding: 5
  }
});

function mapStateToProps(state) {
  return {
    app: state.app,
    HR: state.HR
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(EmployeeBenefit));
