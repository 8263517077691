const Extended = {
  isIE: () => {
    return navigator.userAgent.indexOf("MSIE") > -1 || navigator.userAgent.indexOf("rv:") > -1;
  },
  minHeight: value => {
    return {
      minHeight: value,
      height: value
    };
  }
};

export default Extended;
