import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import { withStyles } from "@mui/styles";
import assets from "../../../../../assets";
import Banner from "./banner";
import DOMPurify from "dompurify";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import React, { Component } from "react";
import RequestCallBackModal from "../../../../../components/hr/benefit/RequestCallBackModal";
import SimpleMessageDialog from "../../../../../components/SimpleMessageDialog";
import BenefitService from "../../../../../services/HR/benefit.service";
import LoadingPopup from "../../../../../components/LoadingPopup";
import PDFViewer from "../../../../../components/pdfViewer";
import { injectIntl } from "react-intl";
import "./banner.css";

class BenefitInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      triggerValidusRequestACallbackDialog: false,
      triggerWellteqRequestACallbackDialog: false,
      triggerSMEhealthRequestACallbackDialog: false,
      triggerHReasilyRequestACallbackDialog: false,
      triggerResultDialog: false,
      triggerLoadingPopup: false,
      pdf: undefined,
      fileName: undefined,
      open: false,
      /**
       * Se default response is success
       */
      responseType: "success"
    };

    this.buttonClickControl = {
      validus: {
        requestACallback: () => {
          this.setState({ triggerValidusRequestACallbackDialog: true });
        },
        getStarted: () => {
          window.open("http://sme.validus.sg/prudential/", "_blank", "noopener noreferrer");
        }
      },
      // hreasily: {
      //   getStarted: () => {
      //     window.open("https://prudential.hreasily.com/", "_blank");
      //     this.callPartnerTransactionDetailsAPI(ENUMS.HREASILY.LEARN_MORE);
      //   },
      //   freeTrial: () => {
      //     window.open("https://app.hreasily.com/user/signup?source=pru", "_blank");
      //     this.callPartnerTransactionDetailsAPI(ENUMS.HREASILY.FREE_TRIAL);
      //   },
      //   downloadBrochure: () => {
      //     const docDesc = this.props.intl.formatMessage({ id: "hreasily.benefit.brochure" });
      //     this.openBrochure(assets.doc, docDesc);
      //     this.callPartnerTransactionDetailsAPI(ENUMS.HREASILY.DOWNLOAD_BROCHURE);
      //   }
      // },
      wellteq: {
        requestACallback: () => {
          this.setState({ triggerWellteqRequestACallbackDialog: true });
        }
      },
      smehealth: {
        requestACallback: () => {
          this.setState({ triggerSMEhealthRequestACallbackDialog: true });
        }
      }
      // stash: {
      //   getStarted: () => {
      //     window.open("https://prustasheb-msite.sg.stashnextgen.io", "_blank");
      //     this.callPartnerTransactionDetailsAPI(ENUMS.STASH.CODE);
      //   }
      // }
    };

    this.callbackSubmitForm = {
      validus: {
        submit: data => {
          this.setState({
            triggerLoadingPopup: true,
            triggerValidusRequestACallbackDialog: false
          });
          BenefitService.sendEmailRequestCallBack(data, this.state.benefitInfo.key).then(
            response => {
              if (response.success) {
                this.setState({ triggerLoadingPopup: false });
                this.showPopupResult(this.state.benefitInfo.key, "success");
              }
            },
            error => {
              let key = "error";
              if (error.code === 400 && error.message.indexOf("exceeded the maximum") > 0) {
                key = "error-max-limit";
              }
              this.setState({ triggerLoadingPopup: false });
              this.showPopupResult(this.state.benefitInfo.key, key);
            }
          );
        }
      },
      smehealth: {
        submit: data => {
          this.setState({
            triggerLoadingPopup: true,
            triggerSMEhealthRequestACallbackDialog: false
          });
          BenefitService.sendEmailRequestCallBack(data, this.state.benefitInfo.key).then(
            response => {
              if (response.success) {
                this.setState({ triggerLoadingPopup: false });
                this.showPopupResult(this.state.benefitInfo.key, "success");
              }
            },
            error => {
              let key = "error";
              if (error.code === 400 && error.message.indexOf("exceeded the maximum") > 0) {
                key = "error-max-limit";
              }
              this.setState({ triggerLoadingPopup: false });
              this.showPopupResult(this.state.benefitInfo.key, key);
            }
          );
        }
      },
      wellteq: {
        submit: data => {
          this.setState({
            triggerLoadingPopup: true,
            triggerWellteqRequestACallbackDialog: false
          });
          BenefitService.sendEmailRequestCallBack(data, this.state.benefitInfo.key).then(
            response => {
              if (response.success) {
                this.setState({ triggerLoadingPopup: false });
                this.showPopupResult(this.state.benefitInfo.key, "success");
              }
            },
            error => {
              let key = "error";
              if (error.code === 400 && error.message.indexOf("exceeded the maximum") > 0) {
                key = "error-max-limit";
              }
              this.setState({ triggerLoadingPopup: false });
              this.showPopupResult(this.state.benefitInfo.key, key);
            }
          );
        }
      }
    };
  }

  /**
   * After sent email success.
   * Show the popup success.
   * We need to close all the popups are opening
   */
  showPopupResult = (partnerKey, responseType) => {
    this.setState({
      // Show success dialog
      triggerResultDialog: true,
      // Response type: success || error
      responseType: responseType,
      // Name of partner: Validus, DocDoc, Wellteq
      partnerName: this.state.benefitInfo.name,
      // Close all popups are opening
      triggerValidusRequestACallbackDialog: false,
      triggerSMEhealthRequestACallbackDialog: false,
      triggerHReasilyRequestACallbackDialog: false,
      triggerWellteqRequestACallbackDialog: false
    });
  };

  closePopupResult = () => {
    this.setState({ triggerResultDialog: false });
  };

  componentDidMount() {
    this.setState({
      benefitInfo: assets.benefits.details.default[this.props.type]
    });
  }

  callPartnerTransactionDetailsAPI(value) {
    BenefitService.sendPartnerTransactionDetails(value, this.state.benefitInfo.key);
  }

  openBrochure(val, docDescription) {
    const document = val.find(({ desc }) => desc === docDescription);
    this.setState({
      pdf: window.location.href.split("/")[0] + "/static/media/" + document.file.split('/').pop(),
      fileName: document.fileName,
      open: true
    });
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, intl } = this.props;
    const { benefitImages } = assets;
    return (
      <div className={classes.root}>
        {this.state.benefitInfo !== undefined ? (
          <div>
            <Banner banner={this.state.benefitInfo} buttonClickControl={this.buttonClickControl} />
            <div className={classes.content}>
              <div className={classes.info}>
                <p className={classes.header}>{this.state.benefitInfo.content.header}</p>
                <p className={classes.desc} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.benefitInfo.content.description) }} />
              </div>
              <Grid container alignItems={"stretch"} spacing={"16px"}>
                <Grid item>
                  <Card className={classes.card}>
                    <CardContent>
                      <List>
                        <ListItem className={classes.highlight}>
                          {this.state.benefitInfo.key !== "stash" ? (
                            <Avatar className={classes.highlightTitleMedia} src={benefitImages.serviceImg} />
                          ) : (
                            ""
                          )}
                          <ListItemText
                            primary={
                              this.state.benefitInfo.key === "smehealth" ? (
                                <span className={classes.highlightTitle}>Types of Programmes</span>
                              ) : this.state.benefitInfo.key === "hreasily" ? (
                                <span className={classes.highlightTitle}>Modules</span>
                              ) : this.state.benefitInfo.key === "stash" ? (
                                <span className={classes.benefitsTitle}>FlexBen Advantage</span>
                              ) : (
                                <span className={classes.highlightTitle}>Services</span>
                              )
                            }
                          />
                        </ListItem>
                      </List>
                      <div className={classes.highlightContent}>
                        <span
                          dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(this.state.benefitInfo.highlights.service)
                          }} 
                        />
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item>
                  <Card className={classes.card}>
                    <CardContent>
                      <List>
                        <ListItem className={classes.highlight}>
                          {this.state.benefitInfo.key !== "stash" ? (
                            <Avatar className={classes.highlightTitleMedia} src={benefitImages.benefitsImg} />
                          ) : (
                            ""
                          )}
                          <ListItemText
                            primary={
                              this.state.benefitInfo.key === "stash" ? (
                                <span className={classes.benefitsTitle}>FlexBen Premium</span>
                              ) : (
                                <p className={classes.highlightTitle}>Benefits</p>
                              )
                            }
                          />
                        </ListItem>
                      </List>
                      <div className={classes.highlightContent}>
                        <span
                          dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(this.state.benefitInfo.highlights.benefits)
                          }} 
                        />
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item>
                  <Card className={classes.card}>
                    <CardContent>
                      <List>
                        <ListItem className={classes.highlight}>
                          {this.state.benefitInfo.key !== "stash" ? (
                            <Avatar className={classes.highlightTitleMedia} src={benefitImages.resultsImg} />
                          ) : (
                            ""
                          )}
                          <ListItemText
                            primary={
                              this.state.benefitInfo.key === "stash" ? (
                                <span className={classes.benefitsTitle}>FlexBen Deluxe (Coming Soon)</span>
                              ) : (
                                <p className={classes.highlightTitle}>Results</p>
                              )
                            }
                          />
                        </ListItem>
                      </List>
                      <div className={classes.highlightContent}>
                        <span
                          dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(this.state.benefitInfo.highlights.results)
                          }} 
                        />
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <div className={classes.backHolder}>
                <Link to={"/auth/employeeBenefit"} className={classes.backLink}>
                  <span className={classes.backButton}>Back to Benefits</span>
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <LinearProgress />
        )}
        <PDFViewer
          open={this.state.open}
          handleClose={this.handleClose}
          pdf={this.state.pdf}
          fileName={this.state.fileName}
        />
        <RequestCallBackModal
          initData={this.props.hrInformation}
          open={this.state.triggerValidusRequestACallbackDialog}
          callback={this.callbackSubmitForm.validus}
        />
        <RequestCallBackModal
          initData={this.props.hrInformation}
          open={this.state.triggerSMEhealthRequestACallbackDialog}
          callback={this.callbackSubmitForm.smehealth}
        />
        <RequestCallBackModal
          initData={this.props.hrInformation}
          open={this.state.triggerHReasilyRequestACallbackDialog}
          callback={this.callbackSubmitForm.hreasily}
        />
        <RequestCallBackModal
          initData={this.props.hrInformation}
          open={this.state.triggerWellteqRequestACallbackDialog}
          callback={this.callbackSubmitForm.wellteq}
          policyMessageID="benefit.requestACallBack.wellteq.policy"
        />
        <SimpleMessageDialog
          type={this.state.responseType}
          name={`request-callback-` + this.state.responseType}
          title={intl.formatMessage({
            id: `benefit.requestACallBack.${this.state.responseType}.title`
          })}
          description={intl.formatMessage(
            {
              id: `benefit.requestACallBack.${this.state.responseType}.description`
            },
            { partnerName: this.state.partnerName }
          )}
          closeButtonText={intl.formatMessage({ id: "popup.button.ok" })}
          closeHandler={this.closePopupResult}
          open={this.state.triggerResultDialog}
        />
        <LoadingPopup name={this.props.type} open={this.state.triggerLoadingPopup} />
      </div>
    );
  }
}

const styles = theme => ({
  root: {},
  iconSuccess: {
    color: `${theme.colors.error}`,
    fontSize: "3rem"
  },
  iconError: {
    color: `${theme.colors.error}`,
    fontSize: "3rem"
  },
  content: {
    padding: "40px",
    backgroundColor: "#f5f5f5",
    zIndex: 100
  },
  card: {
    width: "350px",
    height: "100%",
    paddingLeft: "20px"
  },
  info: {
    color: "#6B6A6D",
    fontSize: 18
  },
  header: {
    marginTop: 0,
    marginBottom: 4,
    color: "#414141"
  },
  desc: {
    textAlign: "justify",
    marginTop: 0,
    marginBottom: 30,
    lineHeight: 1.5
  },
  highlights: {
    backgroundColor: "#F3F3F3",
    padding: "20px 40px"
  },
  highlight: {
    paddingLeft: 0
  },
  highlightTitleMedia: {
    height: 60,
    width: 60
  },
  highlightTitle: {
    fontSize: "24px"
  },
  highlightContent: {
    lineHeight: "30px",
    fontSize: "16px"
  },
  backHolder: {
    paddingTop: "50px",
    display: "flex",
    alignItems: "center"
  },
  backLink: {
    color: "red",
    fontWeight: "bold",
    textDecoration: "none",
    fontSize: "12px"
  },
  backButton: {
    backgroundColor: "white",
    fontWeight: "bold",
    border: "1px solid #ddd",
    padding: "10px 25px",
    borderRadius: "70px"
  },
  benefitsTitle: {
    fontSize: "24px",
    textAlign: "center"
  }
});

function mapStateToProps(state) {
  return { hrInformation: state.user.hrInformation };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(BenefitInfo)));
