import React from 'react';

const NotFound = () => {
  const currentPath = window.location.pathname;

  if (currentPath === "/") return null;

  return (
    <div>
      <div>
        404 Page Not Found
      </div>
    </div>
  );
};

export default NotFound;
