import { Tooltip } from "@mui/material";
import { styled } from "@mui/system";

const CustomTooltip = styled(Tooltip)(({ theme }) => ({
  "&. MuiTooltip-tooltip": {
    backgroundColor: "#ed1b24",
    color: "white",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #ed1b24"
  },
  "& .MuiTooltip-arrow": {
    color: "#ed1b24"
  },
}));

export default CustomTooltip;
