import { Form, Field } from "react-final-form";
import { renderTextField } from "../../components/CustomReduxFormComponent";
import React from "react";
import SimpleControlDialog from "../../components/SimpleControlDialog";

const typographyRootStyle = {
  color: "red",
  whiteSpace: "pre-line",
  textAlign: "center",
  margin: 0,
  fontFamily: "Pru Sans,Roboto,Helvetica,Arial,sans-serif",
  fontWeight: 400,
  fontSize: "0.75rem",
  lineHeight: "1.66"
};
const required = value => (value ? undefined : "Required");

class OtpDialog extends React.Component {
  componentWillReceiveProps(nextProps) {
    //reset form when open
    if (this.props.show !== nextProps.show) this.formApi?.reset();
  }
  submit = values => {
    this.props.submitHandler(values.otp);
  };
  render() {
    return (
      <> 
        <Form 
          onSubmit={this.submit}
          render={({ handleSubmit, form, valid }) => {
            this.formApi = form;
            return (
              <SimpleControlDialog
                show={this.props.show}
                title="Enter OTP"
                description={this.props.description}
                onClose={this.props.closeHandler}
                closeHandler={this.props.closeHandler}
                valid={valid}
                onSubmit={handleSubmit}
                okButtonText="Submit"
                closeButtonText="Cancel"
              >
                <Field
                  name="otp"
                  autoFocus
                  margin="dense"
                  component={renderTextField}
                  autoComplete="[off]"
                  label="OTP"
                  type="text"
                  validate={required}
                />
                {this.props.submitError && (
                  <div variant="caption" style={typographyRootStyle}>
                    {this.props.submitErrorMessage}
                  </div>
                )}
              </SimpleControlDialog>
            )
          }}
        />     
      </>

    );
  }
}
export default OtpDialog;
