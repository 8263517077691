import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { esignActions, documentActions, quoteActions } from "../../../../../../actions";
import { injectIntl } from "react-intl";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import ENUMS from "../../../../../../utils/Enums";
import React, { Component } from "react";
import "./esign.css";
import classNames from "classnames";
import LoadingPopup from "../../../../../../components/LoadingPopup";
import * as _ from "lodash";
import SimpleMessageDialog from "../../../../../../components/SimpleMessageDialog";
import config from "../../../../../../config/config";

class ESign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eEsignResponse: null,
      triggerLoadingPopup: false,
      openConfirmDialog: false,
      errorCode: null,
      errorMessage: null,
      intervalId: null,
      packageIntervalId: null,
      signerStatus: null,
      counter: 0,
      signing_url: null,
      packageStatusCounter: 0,
      showDialogError: false,
      dialogTitle: this.props.intl.formatMessage({
        id: "esign.error.dialogue.title"
      }),
      dialogDescription: this.props.intl.formatMessage({
        id: "esign.document.error"
      })
    };
  }

  getESign = () => {
    const { save, getKofaxSign, quote } = this.props;
    save(ENUMS.STAGES.PROPOSAL).then(
      () => {
        getKofaxSign(quote.id).then(
          response => {
            if (response.data.error_message !== null) {
              this.setState({
                errorCode: response.data.error_code,
                errorMessage: response.data.error_message,
                showDialogError: true
              });
            } else {
              this.setState({
                eEsignResponse: response.data,
                signing_url: response.data.signing_url
              });
              //this.formESign.submit();
              //open url in new tab
              window.open(this.state.signing_url, "_blank", "noopener noreferrer");
              this.getDocumentSignStatus();
            }
          },
          err => {
            this.setState({ showDialogError: true });
            console.error(err);
          }
        );
      },
      err => {
        console.error(err);
      }
    );
  };

  getDocumentSignStatus = () => {
    const intervalId = setInterval(this.signedStatus, config.esign_api_timeout);
    this.setState({
      intervalId: intervalId,
      triggerLoadingPopup: true
    });
    setTimeout(() => {
      clearInterval(this.state.intervalId);
    }, config.esign_api_timeout * config.esign_no_of_calls); //configure the parameters
  };

  signedStatus = () => {
    const { getPackageCallBackResponse, quote } = this.props;
    const packageId = this.state.eEsignResponse.package_id;
    // this.setState({
    //   triggerLoadingPopup: true
    // });
    getPackageCallBackResponse(packageId).then(
      response => {
        //check the response : if status is not signed then call the api every 10 secs
        if (
          response.data === ENUMS.ESIGN.COMPLETE ||
          response.data === ENUMS.ESIGN.REJECTED ||
          response.data === ENUMS.ESIGN.RESUME_LATER
        ) {
          this.setState({
            // triggerLoadingPopup: false,
            signerStatus: response.data
          });
          clearInterval(this.state.intervalId);
          this.getSignedDocument(packageId);
        } else {
          this.setState({
            counter: this.state.counter + 1
            //triggerLoadingPopup: true
          });
          if (this.state.counter === config.esign_no_of_callbacks) {
            this.checkPackageStatuS();
            clearInterval(this.state.intervalId);
          }
        }
      },
      err => {
        this.setState({
          showDialogError: true,
          triggerLoadingPopup: false
        });
      }
    );
  };

  checkPackageStatuS = () => {
    const packageIntervalId = setInterval(this.getPackagesignedStatus, config.esign_api_timeout);
    this.setState({
      packageIntervalId: packageIntervalId,
      triggerLoadingPopup: true
    });
    setTimeout(() => {
      clearInterval(this.state.packageIntervalId);
    }, config.esign_api_timeout * config.esign_no_of_calls);
  };

  getPackagesignedStatus = () => {
    const { getPackageStatus } = this.props;
    const packageId = this.state.eEsignResponse.package_id;
    getPackageStatus(packageId).then(
      response => {
        if (
          response.data === ENUMS.ESIGN.COMPLETE ||
          response.data === ENUMS.ESIGN.REJECTED ||
          response.data === ENUMS.ESIGN.RESUME_LATER
        ) {
          this.setState({
            signerStatus: response.data
            // triggerLoadingPopup: false
          });
          clearInterval(this.state.packageIntervalId);
          this.getSignedDocument(packageId);
        } else {
          this.setState({
            packageStatusCounter: this.state.packageStatusCounter + 1,
            //triggerLoadingPopup: true,
            signerStatus: response.data
          });
          if (this.state.packageStatusCounter === config.esign_no_of_document_status_calls) {
            this.setState({
              triggerLoadingPopup: false,
              showDialogError: true
            });
          }
        }
      },
      err => {
        this.setState({
          showDialogError: true
        });
      }
    );
  };

  getSignedDocument(packageId) {
    const { getSignedDocument, quote } = this.props;
    if (this.state.signerStatus === ENUMS.ESIGN.COMPLETE) {
      getSignedDocument(quote.id, packageId).then(
        response => {
          if (response) {
            window.location.reload();
            this.setState({
              triggerLoadingPopup: false
            });
          } else {
            this.setState({ showDialogError: true });
          }
        },
        err => {
          this.setState({
            showDialogError: true,
            dialogDescription: response
          });
        }
      );
    } else if (this.state.signerStatus == ENUMS.ESIGN.REJECTED) {
      //error message and do not call signed document
      clearInterval(this.state.packageIntervalId);
      this.setState({
        triggerLoadingPopup: false,
        showDialogError: true,
        dialogDescription: this.props.intl.formatMessage({
          id: "esign.document.declined.description"
        })
      });
    } else if (this.state.signerStatus == ENUMS.ESIGN.RESUME_LATER) {
      clearInterval(this.state.packageIntervalId);
      this.setState({
        triggerLoadingPopup: false
      });
    } else {
      this.setState({
        triggerLoadingPopup: false,
        showDialogError: true,
        dialogDescription: this.props.intl.formatMessage({
          id: "esign.document.notsigned.description"
        })
      });
    }
  }

  handleClose = () => {
    this.setState({
      showDialogError: false,
      triggerLoadingPopup: false
    });
  };

  render() {
    const { classes, intl, quote, disabled, eSignUnsupp } = this.props;
    const isActive = this.state.isActive;
    return (
      <div className={classNames(classes.root, eSignUnsupp ? classes.rootEsignUnsupp : null)}>
        <Button
          variant="contained"
          color="primary"
          disabled={disabled}
          onClick={this.getESign}
          className={classNames(classes.button)}
        >
          E-Sign proposal form
        </Button>
        <p className={classNames(classes.addText, eSignUnsupp ? null : classes.hidden)}>
          {/* E-sign is only available on Safari and IE */}
        </p>
        {/* {this.state.eEsignResponse && (
          <form
            method="POST"
            action={this.state.signing_url}
            className={classes.hidden}
            target="_blank"
            ref={formESign => {
              this.formESign = formESign;
            }}
          />
        )} */}
        <LoadingPopup name={"kofax"} open={this.state.triggerLoadingPopup} />

        <SimpleMessageDialog
          type="error"
          name="error-document-sign"
          title={this.state.dialogTitle}
          description={this.state.dialogDescription}
          open={this.state.showDialogError}
          closeHandler={this.handleClose}
          closeButtonText={intl.formatMessage({ id: "popup.button.ok" })}
        />
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    textAlign: "center"
  },
  rootEsignUnsupp: {
    paddingTop: "32.4px"
  },
  hidden: {
    display: "none"
  },
  button: {
    width: "100%"
  },
  addText: {
    fontSize: "0.7em",
    textAlign: "center"
  }
});

function mapStateToProps(state, prop) {
  return {
    quote: state.quote
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(esignActions, dispatch),
    ...bindActionCreators(quoteActions, dispatch),
    documentActions: bindActionCreators(documentActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles, { withTheme: true })(ESign)));
