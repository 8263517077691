import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { esignActions, quoteActions, appActions } from "../../../../../../actions";
import { injectIntl } from "react-intl";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import ENUMS from "../../../../../../utils/Enums";
import Util from "../../../../../../utils/Util";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import React, { Component } from "react";
import SendDocumentForm from "./SendDocumentForm";
import SnackBar from "../../../../../../components/SnackBar";
import moment from "moment";
import LoadingPopup from "../../../../../../components/LoadingPopup";
import "./sendcontainer.css";
import classNames from "classnames";
import PDFViewer from "../../../../../../components/pdfViewer";
import quoteService from "../../../../../../services/quote.service";
import captchaService from "../../../../../../services/captcha.service";
import FirstPageDialog from "../../../../../../containers/Auth/components/FirstPageDialog";
import config from "../../../../../../config/config";

const docFiles = [
  "Proposal Form",
  "MAS314.pdf",
  "Health Declaration Form",
  "Product Summaries",
  "PRUTreasure Flexi II Brochure",
  "Declaration of Entitlement to Claim Input Tax on Insurance Policy"
];
class SendContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSendDocumentDialog: false,
      triggerLoadingPopup: false,
      snackBarErrorShow: false,
      eEsignResponse: null,
      pdf: undefined,
      openPDF: false,
      captcha: {},
      captchaErrorMessage: "",
      defaultErrorMessage: "We were unable to send the email. Please try again."
    };
  }

  componentWillMount() {
    this.generateCaptcha();
    this.props.setFirstPageDialogVisibility(false);
  }

  generateCaptcha = () => {
    captchaService.generateCaptcha().then(res => {
      if (res.data) {
        this.setState({
          captcha: res.data,
          captchaCode: "",
          captchaErrorMessage: ""
        });
      }
    });
  };

  refreshCaptcha = () => {
    let trxId = this.state.captcha.trx_id;
    captchaService.refreshCaptcha(trxId).then(res => {
      if (res.data) {
        this.setState({
          captcha: res.data,
          captchaCode: "",
          captchaErrorMessage: ""
        });
      } else {
        console.log("invalid request");
      }
    });
  };

  handleCaptchaChange = event => {
    this.setState({ captchaCode: event.target.value });
  };

  onPdfClick = e => {
    e.stopPropagation();
    const { save, quote } = this.props;
    let quoteId = quote.id;

    save(ENUMS.STAGES.EMPLOYEE).then(() => {
      quoteService.previewPdf(quoteId).then(
        res => {
          const url = window.URL.createObjectURL(new Blob([res.data], { type: "application/pdf" }));
          this.setState({
            openPDF: true,
            pdf: url,
            fileName: `${quoteId}_Proposal.pdf`
          });
        },
        err => {
          err.data.text().then(text => {
            var errObj = JSON.parse(text);
            this.setState({
              defaultErrorMessage: errObj.message,
              snackBarErrorShow: true
            });
          });
        }
      );
    });
  };

  handlePDFClose = () => {
    this.setState({ openPDF: false });
  };

  renderFormatted_activation_date = () => {
    const { req_effective_date } = this.props.quote;
    if (req_effective_date) return moment(this.props.quote.req_effective_date, "DD-MM-YYYY").format("DD - MMM - YYYY");
    return "Not Selected";
  };

  render_actual_amount = () => {
    const { quote, intl } = this.props;
    const { premiums } = quote;
    const currencySymbol = "S$";
    const total = premiums.actual.final || 0;
    return `${currencySymbol} ${intl.formatNumber(total)}`;
  };

  handleDocumentClose = () => {
    this.props.dependantSnackbarToggle(false);
    this.setState({
      openSendDocumentDialog: false
    });
  };

  openSendDocumentDialog = () => {
    if (!quoteActions.isValid(this.props.quote)) {
      this.props.dependantSnackbarToggle(true);
      return;
    }
    this.setState({
      openSendDocumentDialog: true
    });
  };

  handleEmailQuoteToHR = () => {
    this.setState({ triggerLoadingPopup: true, openSendDocumentDialog: false });
    this.props.handleResetEdits();
    const { save, submitGenerateQuote, form, quote } = this.props;
    let companyDetailForm = this.props.form["company-detail"];
    let sendDocumentForm = this.props.form["send-document"];
    const data = {
      email: sendDocumentForm.values.email,
      firstname: companyDetailForm.values.agent_first_name,
      lastname: companyDetailForm.values.agent_last_name,
      agent_code: companyDetailForm.values.agent_code,
      agent_email: companyDetailForm.values.agent_email,
      trx_id: this.state.captcha.trx_id,
      captcha_code: this.state.captchaCode
    };
    save(ENUMS.STAGES.PROPOSAL).then(
      () => {
        submitGenerateQuote(data).then(
          response => {
            this.setState({
              triggerLoadingPopup: false,
              defaultErrorMessage: "We were unable to send the email. Please try again.",
              captchaErrorMessage: ""
            });
          },
          err => {
            if (err.message === "Invalid Captcha trx id entered") {
              this.setState({
                defaultErrorMessage: "Your code has expired, please refresh your captcha",
                captchaErrorMessage: "Your code has expired, please refresh your captcha"
              });
            } else if (err.message === "Invalid Captcha Code entered") {
              this.setState({
                defaultErrorMessage: "You have entered an invalid captcha code please try again",
                captchaErrorMessage: "You have entered an invalid captcha code please try again"
              });
            }
            this.setState({
              triggerLoadingPopup: false,
              snackBarErrorShow: true
            });
          }
        );
      },
      err => {
        this.setState({ triggerLoadingPopup: false, snackBarErrorShow: true });
      }
    );
  };

  render() {
    const { classes, companyDetail, intl, form, quote, disabled } = this.props;

    const dialogNbstpDisabled = Util.isDialogNbstpEnabled(config.disableBefore, config.dialogAutoDisable);

    return (
      <Grid container className={classes.root}>
        <Grid item md={8}>
          <div>
            <List>
              {docFiles.map((d, i) => {
                return (
                  <ListItem
                    key={i}
                    classes={{
                      gutters: classes.gutters,
                      default: classes.default
                    }}
                  >
                    <i className={disabled ? classNames("material-icons", classes.iconWhenDisable) : "material-icons"}>
                      description
                    </i>
                    <ListItemText classes={{ root: classes.listItemText }} primary={d} />
                    {i === 0 && (
                      <Button
                        color="primary"
                        disabled={disabled}
                        className={classes.previewButton}
                        onClick={this.onPdfClick}
                      >
                        Preview Form
                      </Button>
                    )}
                  </ListItem>
                );
              })}
            </List>
          </div>
        </Grid>
        <Grid item md={4} className={classes.buttonOnBottom}>
          <Button variant="contained" color="primary" disabled={disabled} onClick={this.openSendDocumentDialog}>
            Send
          </Button>
        </Grid>
        <PDFViewer
          isPDF={true}
          open={this.state.openPDF}
          handleClose={this.handlePDFClose}
          fileName={this.state.fileName}
          pdf={this.state.pdf}
        />
        <Dialog
          classes={{ paper: classes.dialogPaper }}
          open={this.state.openSendDocumentDialog}
          aria-labelledby="document-send-dialog"
        >
          <DialogTitle className={classes.dialogTitle}>Send Documents</DialogTitle>
          <DialogContent className={classes.dialogContent} classes={{ root: classes.dialogContentRoot }}>
            <SendDocumentForm
              companyName={companyDetail.name}
              activationDate={this.renderFormatted_activation_date()}
              actualAmount={this.render_actual_amount()}
              captcha={this.state.captcha}
              refreshCaptcha={this.refreshCaptcha}
              captchaEntered={this.handleCaptchaChange}
              captchaErrorMessage={this.state.captchaErrorMessage}
              email={companyDetail.contact_email}
              handleForm={this.props.handleForm}
            />
          </DialogContent>
          <DialogActions
            classes={{
              root: classes.dialogActionsRoot,
              action: classes.dialogActionsButton
            }}
          >
            <Button
              className={classNames(classes.buttonCancel)}
              variant="raised"
              onClick={this.handleDocumentClose}
              fullWidth
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              autoFocus
              fullWidth
              onClick={this.handleEmailQuoteToHR}
              disabled={this.props.form?.["send-document"] && Object.values(this.props.form["send-document"].errors || {}).length > 0}
            >
              Send
            </Button>
          </DialogActions>
        </Dialog>
        <SnackBar
          type="error"
          open={this.state.snackBarErrorShow}
          onClose={() => this.setState({ snackBarErrorShow: false })}
          message={this.state.defaultErrorMessage}
        />
        <LoadingPopup
          title="popup.loading.sendQuote.title"
          description="popup.loading.sendQuote.description"
          subDescription="popup.loading.sendQuote.subDescription"
          name="send-document-loading"
          open={this.state.triggerLoadingPopup}
        />

        {config.firstPageDialogEnabled && dialogNbstpDisabled ? (
          <FirstPageDialog message={config.firstPageDialogDetails} />
        ) : null}
      </Grid>
    );
  }
}

const styles = theme => ({
  root: {},
  iconEditContainer: {
    paddingRight: 15
  },
  buttonCancel: {
    backgroundColor: "transparent",
    border: `2px solid ${theme.colors.blackScale.black60}`,
    color: "#000"
  },
  iconEdit: {
    padding: 3,
    backgroundColor: theme.colors.blackScale.black100,
    color: theme.colors.secondary.white,
    width: 24,
    height: 24,
    fontSize: 18,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100%"
  },
  iconWhenDisable: {
    color: "rgba(0, 0, 0, 0.12)"
  },
  iconEditWhenDisable: {
    backgroundColor: "rgba(0, 0, 0, 0.12)"
  },
  iconSuccess: {
    color: `${theme.colors.error}`,
    fontSize: "3rem"
  },
  listItemText: {
    paddingLeft: 5,
    flexGrow: 0
  },
  dialogPaper: {
    padding: "10px 40px"
  },
  titleWithIcon: {
    display: "flex",
    alignItems: "center"
  },
  dialogSendDocumentContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 20
  },
  dialogContent: {
    width: "25rem"
  },
  dialogTitle: {
    textAlign: "center"
  },
  dialogContentRoot: {
    paddingBottom: 0
  },
  dialogActionsRoot: {
    justifyContent: "center",
    margin: "20px 0"
  },
  dialogActionsButton: {
    width: "42%"
  },
  box: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px 20px",
    marginBottom: 15,
    border: `2px solid ${theme.colors.blackScale.black60}`,
    backgroundColor: theme.colors.secondary.white
  },
  gutters: {
    paddingLeft: 0
  },
  default: {
    paddingTop: 15,
    paddingBottom: 0
  },
  buttonOnBottom: {
    textAlign: "right",
    alignSelf: "flex-end"
  },
  previewButton: {
    border: `1px solid ${theme.colors.primary.red}`
  }
});

function mapStateToProps(state, prop) {
  return {
    quote: state.quote,
    companyDetail: state.company.details
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(appActions, dispatch),
    ...bindActionCreators(esignActions, dispatch),
    ...bindActionCreators(quoteActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(SendContainer)));
