import React, { Component, Fragment } from "react";
import { injectIntl } from "react-intl";

import { quoteActions } from "../../../../../../../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { withStyles } from "@mui/styles";
import DOMPurify from "dompurify";

class PaymentInstruction extends Component {
  componentWillMount() {
    this.init();
  }

  init = () => {
    const { method, setPaymentMethod } = this.props;
    setPaymentMethod(method);
  };

  render() {
    const { classes, content } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.container} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    border: "solid 1px rgba(0,0,0,0.4)"
  },
  container: {
    padding: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit,

    "& li": {
      paddingBottom: "8px"
    },

    "& table,td": {
      border: "solid 1px rgba(0,0,0,0.2)",
      borderCollapse: "collapse"
    },

    "& td": {
      padding: "15px"
    }
  }
});

function mapStateToProps(state, prop) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return { ...bindActionCreators(quoteActions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(PaymentInstruction)));
