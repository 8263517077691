import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import util from "../utils/Util.js";

import { user } from "./user.reducer";
import { products } from "./product.reducer";
import { quote, initialState as quoteInitialState } from "./quote.reducer";
import { company } from "./company.reducer";
import { renewPolicies } from "./renewPolicies.reducer";
import { policyRenew } from "./policyRenew.reducer";
import { company_cache } from "./companyCache.reducer";
import { app } from "./app.reducer";
import { document } from "./document.reducer";
import { quotes } from "./quotes.reducer";
import { esign } from "./esign.reducer";

//HR
import { clientsAndPolicyListing } from "./HR/clientandpolicylisting.reducer.js";
import { coverage } from "./HR/coverage.reducer";
import { myemployee } from "./HR/myemployee.reducer.js";
import { claimexpense } from "./HR/claimexpense.reducer.js";

import * as actionTypes from "../actions/types";
import config from "../config/config";
import { policyDetails } from "./policyDetails.reducer.js";
import { policyMyEmployees } from "./policyMyEmployees.reducer";
import { policyList } from "./policyList.reducer";
import { validations } from "./validations.reducer.js";
import { otp } from "./otp.reducer.js";
import { policyClaimExpense } from "./policyClaimExpense.reducer.js";
import { encryptTransform } from "../utils/encryptTransform.js";

const salt = await util.hashSHA512(config.app.build);

const persisConfigBuilder = (name) => ({
  key: name,
  storage,
  transforms: [
    encryptTransform({ secretKey: salt })
  ]
});

const userPersistConfig = persisConfigBuilder("user");
const renewPersistConfig = persisConfigBuilder("renewPolicies");
const quotePersistConfig = persisConfigBuilder("quote");
const companyPersistConfig = persisConfigBuilder("company");
const policyDetailsPersistConfig = persisConfigBuilder("policyDetails");
const policyRenewPersistConfig = persisConfigBuilder("policyRenew");

const appReducer = combineReducers({
  user: persistReducer(userPersistConfig, user),
  products,
  quote: persistReducer(quotePersistConfig, quote),
  company: persistReducer(companyPersistConfig, company),
  company_cache,
  app,
  document,
  quotes,
  esign,
  HR: combineReducers({
    clientsAndPolicyListing,
    coverage,
    myemployee,
    claimexpense
  }),
  policyList,
  policyDetails: persistReducer(policyDetailsPersistConfig, policyDetails),
  policyMyEmployees,
  validations,
  otp,
  policyClaimExpense,
  renewPolicies: persistReducer(renewPersistConfig, renewPolicies),
  policyRenew: persistReducer(policyRenewPersistConfig, policyRenew)
});

const rootReducer = (state, action) => {
  if (action.type === actionTypes.USER_LOGOUT || action.type === actionTypes.APP_RESET) {
    state = Object.assign(state, {
      quote: {
        ...quoteInitialState,
        _persist: state.quote._persist
      },
      user: {
        _persist: state.user._persist
      }
    });
  }
  return appReducer(state, action);
};

export default rootReducer;
