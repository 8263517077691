import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import promiseMiddleware from './promiseMiddleware';
import { persistStore, persistReducer } from "redux-persist";
//import storageSession from 'redux-persist/lib/storage/session'
//import storage from "redux-persist/lib/storage";
import storage from "redux-persist/lib/storage/session";

import reducer from "./reducers";

/* eslint-disable no-underscore-dangle */
const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(thunk, promiseMiddleware));

const persistConfig = {
  key: "reduxStore",
  whitelist: ["app", "products"],
  storage
};

const persistedReducer = persistReducer(persistConfig, reducer);
/* eslint-enable */

export const store = createStore(persistedReducer, enhancer);
export const persistor = persistStore(store);
