import FormHelperText from "@mui/material/FormHelperText";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import React, { PureComponent } from "react";
import TextField from "@mui/material/TextField";

class TextFieldWithValidator extends PureComponent {
  render() {
    const { classes, input, label, children, isSelect, onChange, ...custom } = this.props;
    const { error, warning } = this.props.meta;
    return (
      <div>
        <TextField margin="normal" fullWidth error={error !== undefined} label={label} {...input} {...custom}         
          onChange={(e) => {
            input.onChange(e);
            if (onChange) {
              onChange(e, e.target.value, input.value, input.name);
            }
          }}
        >
          {children}
        </TextField>
        {(error && (
          <FormHelperText error={true} classes={{ error: classes.error }} className={classNames({ "m-b-10": error })}>
            {error}
          </FormHelperText>
        )) ||
          (warning && (
            <FormHelperText error={true} className={classNames({ "m-b-10": warning })}>
              {warning}
            </FormHelperText>
          ))}
      </div>
    );
  }
}

const styles = theme => ({
  error: {
    whiteSpace: "pre-line"
  }
});

export default withStyles(styles, { withTheme: true })(TextFieldWithValidator);
