import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import assets from "../../../../assets";
import OfferInfo from "./offerInfo";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import DOMPurify from "dompurify";

class Offer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productIndex: 0
    };
  }

  handleChangeProductIndex = productIndex => {
    this.setState({ productIndex });
  };

  componentWillReceiveProps(next) {
    if (this.props.productCategory !== next.productCategory) this.setState({ productIndex: 0 });
  }

  render() {
    const { productCategory, classes, productInfo } = this.props;
    const { productIndex } = this.state;
    const categoryProducts = productInfo.products[productCategory];
    const { introImages } = assets;
    const more_services_category = categoryProducts.filter(category => {
      return category.id.toLowerCase().match("more_services");
    });

    return more_services_category && more_services_category.length > 0 ? (
      <Card className={classes.card_ms}>
        <CardContent className={classes.content}>
          <div className={classes.info_ms}>
            <Typography className={classes.title_ms} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(more_services_category[0].header) }} />
            <Typography component="p" className={classes.description_ms}>
              {more_services_category[0].desc}
            </Typography>
          </div>
        </CardContent>
      </Card>
    ) : (
      <div className={classes.root}>
        <Grid container justify="center" spacing={"40px"} className={classes.subNav}>
          {categoryProducts &&
            categoryProducts.map(({ icons, iconHeader }, index) => {
              const active = index === productIndex;
              return (
                <Grid
                  key={"category" + index}
                  onClick={() => {
                    this.handleChangeProductIndex(index);
                  }}
                  item
                >
                  <img
                    className={classes.subNavIcon}
                    alt="Med icon"
                    src={introImages[active ? icons.active : icons.inactive]}
                  />
                  <br />
                  <span className={!active ? classes.subNavInactive : null}>{iconHeader}</span>
                </Grid>
              );
            })}
        </Grid>

        <OfferInfo productInfo={productInfo} productCategory={productCategory} productIndex={productIndex} />
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    background: "white"
  },
  subNav: {
    padding: 80,
    marginTop: 10,
    textAlign: "center",
    fontSize: "14px"
  },
  subNavInactive: {
    textAlign: "center",
    color: "#8F8F8F"
  },
  subNavIcon: {
    paddingBottom: 20
  },
  info_ms: {
    padding: "60px 150px"
  },
  card_ms: {
    minHeight: 250
  },
  description_ms: {
    color: "#333",
    fontSize: "15px",
    textAlign: "center"
  },
  title_ms: {
    fontSize: "22px",
    color: "#848689",
    verticalAlign: "text-top",
    paddingTop: "11px",
    paddingBottom: "15px",
    textAlign: "center"
  }
});

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Offer));
