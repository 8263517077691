import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { IntlProvider } from "react-intl";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { defaultLocale, getMessages } from "../../intl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import routes from "../../routes";
import muiTheme from "../../themes";
import "./App.css";
import { appActions } from "../../actions";
import Enums from "../../utils/Enums";
import { LocalizationProvider as MuiDatePickerLocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import SnackBar from "../../components/SnackBar";
import config from "../../config/config";
import { createGenerateClassName, StylesProvider } from "@mui/styles";
import { FormProvider } from "../../context/FormContext";
import { pageLoadTime } from "../../utils/Helper";

const generateClassName = createGenerateClassName({
  productionPrefix: "tw"
});

class App extends Component {
  constructor(props) {
    super(props);

    const { app } = props;

    this.state = {
      showLangSelection: false,
      locale: app.locale || defaultLocale,
      currentPath: ""
    };
  }

  handleCloseSnackbarError = () => {
    this.props.clearError();
  };

  componentWillMount() {
    this.props.init();
  }

  componentDidMount() {
    let pathname = window.location.href;
    const gtmId = this.getGTMId(); // determine which GTM ID is used based on the portal
    (function(w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", gtmId);

    pageLoadTime(pathname);
  }

  componentDidUpdate(prevProps, prevState) {
    let pathname = window.location.href;

    pageLoadTime(pathname);
  }

  getGTMId() {
    const { href } = window.location;
    let nonProductionUrlStrings = ["dev", "sit", "stg", "uat"];

    // check the url to differentiate bw fc portal and hr portal
    if (href.includes("fc-pruworks")) {
      if (nonProductionUrlStrings.some(url => href.includes(url))) {
        return "GTM-WLSNZZVD"; // GTM id for non production fc portal
      } else {
        return "GTM-584Z6VR6"; // GTM id for production fc portal
      }
    } else if (href.includes("hr-pruworks")) {
      if (nonProductionUrlStrings.some(url => href.includes(url))) {
        return "GTM-NLX3WZDM"; // GTM id for non production hr portal
      } else {
        return "GTM-W4W7NPJ3"; // GTM id for production hr portal
      }
    }
  }

  handleChangeLocale = e => {
    let target = e.target;

    this.setState({ locale: target.value }, () => {
      this.props.setLocale(this.state.locale);
    });
  };

  render() {
    const { locale } = this.state;
    const { app } = this.props;
    const maintenancePath = "/maintenance";
    let i18Messages = getMessages(locale);
    let contentLangSelector = null;
    let currentDateTime = new Date().getTime();
    let startDateTime = parseInt(config.startDateTime);
    let endDateTime = parseInt(config.endDateTime);
    let showMaintenace = false;
    if (startDateTime < currentDateTime && endDateTime > currentDateTime) {
      showMaintenace = true;
    }

    if (this.state.showLangSelection) {
      contentLangSelector = (
        <Select value={this.state.locale} onChange={this.handleChangeLocale}>
          <MenuItem value={"en"}>EN</MenuItem>
          <MenuItem value={"zh"}>ZH</MenuItem>
        </Select>
      );
    }

    //Render routes
    let appRoutes;
    if (this.props.app.type === Enums.APP_TYPE.SALES) {
      appRoutes = routes.SalesRoutes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          render={(props) => (
            <route.component {...props} routes={route.routes} />
          )}
        />
      ))
    } else if (this.props.app.type === Enums.APP_TYPE.HR) {
      appRoutes = routes.HRRoutes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          render={(props) => (
            <route.component {...props} routes={route.routes} />
          )}
        />
      ))
    }

    return (
      <>
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${this.getGTMId()}`}
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>
        </noscript>
        <IntlProvider locale={locale} messages={i18Messages}>
          <MuiDatePickerLocalizationProvider dateAdapter={AdapterMoment}>
            <BrowserRouter>
              <StyledEngineProvider injectFirst>
                <StylesProvider generateClassName={generateClassName} injectFirst>
                  <ThemeProvider theme={muiTheme}>
                    <CssBaseline />
                    {contentLangSelector}
                    <div className="App">
                      <Switch>
                        {this.props.app.type === Enums.APP_TYPE.SALES ? <FormProvider>{appRoutes}</FormProvider> : appRoutes}
                      </Switch>
                      <SnackBar
                        type="error"
                        open={app.error500 && app.error500.fail}
                        onClose={this.handleCloseSnackbarError}
                        message="There was a problem connecting to the server. Please try again later."
                      />
                      {showMaintenace && config.maintenanceEnabled ? (
                        <Redirect to="/maintenance" />
                      ) : maintenancePath === window.location.pathname ? (
                        <Redirect to="/" />
                      ) : (
                        ""
                      )}
                    </div>
                  </ThemeProvider>
                </StylesProvider>
              </StyledEngineProvider>
            </BrowserRouter>
          </MuiDatePickerLocalizationProvider>
        </IntlProvider>
      </>
    );
  }
}

function mapStateToProps(state) {
  return { app: state.app };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(appActions, dispatch),
    clearError: () => {
      dispatch(appActions.set500Error({}));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
