import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import { withStyles } from "@mui/styles";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Icon from "@mui/material/Icon";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import { quoteActions } from "../../../../../../actions";
import Util from "../../../../../../utils/Util";
import plansCode from "../../../../../../assets/data/plansCode";
import ProductEngineService from "../../../../../../services/product.engine.service";
import Typography from "@mui/material/Typography";
import Enums from "../../../../../../utils/Enums";
import Grid from "@mui/material/Grid";
import ListItemText from "@mui/material/ListItemText";
import { Accordion as ExpansionPanel } from "@mui/material";
import { AccordionSummary as ExpansionPanelSummary } from "@mui/material";
import { AccordionDetails as ExpansionPanelDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Modal from "@mui/material/Modal";
import Plans from "../../../Plans";

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: null,
      activeButton: new Map(),
      lastUpdateTime: null,
      open: false,
      errorCategoryName: false,
      errorCategoryNameText: ""
    };
    this.handlers = {
      validate: Util.debounce(200, this.validate),
      checkIfAllPlanValid: Util.debounce(200, this.checkIfAllPlanValid)
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  checkActiveButton = (planCode, tier) => {
    if (!this.state.activeButton.get(planCode)) {
      return false;
    }

    if (this.state.activeButton.get(planCode) == tier) {
      return true;
    }
    return false;
  };

  handleButtonOnClick = (planCode, tier) => {
    if (!this.state.activeButton.get(planCode)) {
      // if activeButton.get(planCode) is undefined set Key as PlanCode and Value as 0
      this.setState(this.state.activeButton.set(planCode, 0));
    }

    // if activeButton.get(planCode) value is not equal to tier overwrite value in map
    if (this.state.activeButton.get(planCode) !== tier) {
      this.state.activeButton.set(planCode, tier);
    } else {
      this.state.activeButton.set(planCode, 0);
    }

    this.setState({ lastUpdateTime: new Date() });
    // else activeButton.get(planCode) value is equal to tier set value to 0
  };

  renderError = validation => {
    const { classes } = this.props;

    if (!validation || (validation && validation.valid)) return null;
    return (
      <FormHelperText id="name-error-text" error className={classes.error}>
        {validation.error}
      </FormHelperText>
    );
  };

  validateCategoryName = value => {
    const reg = new RegExp(Util.nbstpFieldRegex);
    const regexResult = reg.test(value);
    this.setState({
      errorCategoryName: !regexResult || value.trim() === "",
      errorCategoryNameText:
        !regexResult && value
          ? "Invalid characters in the category field"
          : value.trim() === ""
          ? "Field is required"
          : ""
    });
  };

  handleCategoryNameChange = id => event => {
    let target = event.target;

    this.props.handleInputChange(id)(event);
  };

  componentWillReceiveProps(nextProps) {
    this.handlers.validate();
    this.handlers.checkIfAllPlanValid();
  }

  validate = props => {
    const { estimate, category, quote, document } = this.props;
    const { data } = quote;
    const { categories, estimates } = data;

    let hasPlansSelection =
      category.plansConfiguration.filter(c => {
        return !Util.isEmpty(c.tier) || c.enableDependent;
      }).length > 0;

    let _categories = [];
    categories.forEach((category, idx) => {
      //Populate categories values
      _categories.push({
        name: category.name,
        plansConfiguration: category.plansConfiguration.filter(c => {
          return !Util.isEmpty(c.tier);
        })
      });
    });

    let _estimates = estimates.map((e, idc) => {
      return {
        averageAge: Util.parseInteger(e.averageAge),
        name: e.name,
        noOfEmployee: e.noOfEmployee ? Util.parseInteger(e.noOfEmployee) : 0
      };
    });

    //Validate only when category selection is not empty
    if (!Util.isEmpty(category.name) || hasPlansSelection || !Util.hasEmptyValues(estimate)) {
      //Filter out empty tiers
      // let categories = [category].map(cat => ({
      //   ...cat,
      //   plansConfiguration: cat.plansConfiguration.filter(c => {
      //     return !Util.isEmpty(c.tier);
      //   })
      // }));

      ProductEngineService.validateCategoryAndEstimate(document.hasSignedProposal, _categories, _estimates).then(
        resp => {
          this.setState({ validation: null });
        },
        err => {
          err.error = Util.mapPlansCodeLabel(err.error);
          this.setState({ validation: err });
        }
      );
    } else {
      this.setState({ validation: null });
    }
    if (estimate.averageAge && estimate.noOfEmployee && estimate.averageAge !== 0 && estimate.noOfEmployee !== 0) {
      this.props.setStageValidation(Enums.STAGES.ESTIMATE, true);
    } else {
      this.props.setStageValidation(Enums.STAGES.ESTIMATE, false);
    }
  };

  checkIfAllPlanValid = props => {
    const { quote, document } = this.props;
    const { data } = quote;
    const { categories, estimates } = data;

    let _categories = [];
    categories.forEach((category, idx) => {
      //Populate categories values
      _categories.push({
        name: category.name,
        plansConfiguration: category.plansConfiguration.filter(c => {
          return !Util.isEmpty(c.tier);
        })
      });
    });

    let _estimates = estimates.map((e, idc) => {
      return {
        averageAge: Util.parseInteger(e.averageAge),
        name: e.name,
        noOfEmployee: e.noOfEmployee ? Util.parseInteger(e.noOfEmployee) : 0
      };
    });

    let validates = ProductEngineService.validateCategoryAndEstimate(
      document.hasSignedProposal,
      _categories,
      _estimates
    );
    Promise.resolve(validates).then(
      resolve => {
        Util.isPlanValid = true;
      },
      errors => {
        Util.isPlanValid = false;
      }
    );
  };

  errorIndicator = field => {
    const { classes } = this.props;

    if (!field) {
      return null;
    }

    return <Icon className={classes.iconError}>warning</Icon>;
  };

  render() {
    const {
      classes,
      id,
      readOnly,
      estimate,
      category,
      plans,
      categoryTitle,
      expandPanel,
      handleRemoveCategory,
      handleInputChange,
      handleToggleAllowDependents,
      handleExpandPanel,
      quote
    } = this.props;
    const { validation } = this.state;

    if (!plans || !category) return null;
    return (
      <div className={classes.root}>
        <ExpansionPanel
          expanded={expandPanel}
          onChange={(event, expanded) => {
            handleExpandPanel(id, expanded);
          }}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.expStyle}>
            <Toolbar className={classes.containerToolbar}>
              <div className={classes.leftActionsToolbar}>
                <Typography className={classes.employeeTitle}>
                  {categoryTitle + " - "} {estimate.name}
                </Typography>
              </div>
              <div style={{ flexGrow: 1 }} />
              <div className={classes.actionsToolbar}>
                <Button
                  disabled={readOnly}
                  style={{
                    minWidth: "30px",
                    minHeight: "30px"
                  }}
                  variant="flat"
                  mini
                  size="small"
                  color="primary"
                  aria-label="remove"
                  onClick={() => handleRemoveCategory(id)}
                >
                  <Icon className={classes.leftIcon}>clear</Icon>
                </Button>
                <Divider
                  style={{
                    display: "flex",
                    rotate: "90deg",
                    maxWidth: "50%"
                  }}
                />
              </div>
            </Toolbar>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            {/* direction="row" */}
            <Grid container>
              <Grid item sm={1} md={1} lg={1} />

              {/* Estimates =============================== */}
              <Grid item sm={3} md={2} lg={2}>
                <Grid item>
                  <Typography className={classes.labelTitle}>Define Category</Typography>
                </Grid>
              </Grid>

              <Grid item sm={7} md={8} lg={8} rowSpacing={"2px"}>
                <Grid container direction="row">
                  <Grid item sm={12} md={12} lg={4}>
                    <Typography className={classes.labelTitle}>
                      Category Name {this.errorIndicator(estimate.nameError)}
                    </Typography>

                    <TextField
                      id="name"
                      name="name"
                      className={classes.textField}
                      disabled={readOnly}
                      value={estimate.name || ""}
                      autoComplete="[off]"
                      size="small"
                      onChange={this.handleCategoryNameChange(id)}
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{
                        maxLength: 40
                      }}
                      style={{ width: "98%" }}
                    />
                  </Grid>

                  <Grid item sm={12} md={12} lg={4}>
                    <Typography className={classes.labelTitle}>
                      No. of Employees {this.errorIndicator(estimate.noOfEmployeeError)}
                    </Typography>

                    <TextField
                      type="number"
                      id="noOfEmployee"
                      className={classes.textField}
                      disabled={readOnly}
                      name="noOfEmployee"
                      value={estimate.noOfEmployee || ""}
                      onChange={handleInputChange(id, null, "noEmployee")}
                      onInput={e => {
                        e.target.value = Math.max(0, parseInt(e.target.value, 0)).toString();
                      }}
                      inputProps={{
                        pattern: "[0-9]"
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      style={{ width: "98%" }}
                    />
                  </Grid>

                  <Grid item sm={12} md={12} lg={4}>
                    <Typography className={classes.labelTitle}>
                      Average Age {this.errorIndicator(estimate.averageAgeError)}
                    </Typography>

                    <TextField
                      type="number"
                      id="averageAge"
                      className={classes.textField}
                      disabled={readOnly}
                      name="averageAge"
                      value={estimate.averageAge || ""}
                      onInput={e => {
                        e.target.value = Math.max(0, parseInt(e.target.value, 0))
                          .toString()
                          .slice(0, 3);
                      }}
                      onChange={handleInputChange(id, null, "avgAge")}
                      inputProps={{
                        pattern: "[0-9]{0,3}"
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      style={{ width: "98%" }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item sm={1} md={1} lg={1} />
              {/* Employee Plans For Core =============================== */}
              {/* Dependants Plans =============================== */}
              <Grid item sm={1} md={1} lg={1} />
              <Grid item sm={3} md={2} lg={2}>
                <Grid item>
                  {/* This is for Select Core Plans*/}
                  <Typography className={classes.labelTitle}>Select Core Plans</Typography>
                  <Typography>Please select an option</Typography>
                  <Typography>
                    <button
                      type="button"
                      id="view plan"
                      onClick={this.handleOpen}
                      style={{
                        color: "red",
                        background: "none",
                        border: "none",
                        margin: "0",
                        padding: "0",
                        cursor: "pointer",
                        textDecoration: "underline"
                      }}
                    >
                      View Plan Details
                    </button>
                    <Modal open={this.state.open} onClose={this.handleClose}>
                      <Grid container direction="row">
                        <Grid item sm={1} md={1} lg={1} />
                        <Grid item sm={10} md={10} lg={10}>
                          <div className={classes.popup}>
                            <div className={classes.containerPopup}>
                              <h3 className={classes.popupTitle} style={{ padding: 5 }}>
                                Product Information
                              </h3>
                              <Paper
                                className={classes.paper}
                                style={{
                                  padding: 8
                                }}
                              >
                                <Plans
                                  portalType="Sales"
                                  resetTime={parseInt(quote.resetTime)}
                                  productCode={quote.product_code}
                                />
                              </Paper>
                            </div>
                          </div>
                        </Grid>
                        <Grid item sm={1} md={1} lg={1} style={{ paddingTop: "2%" }}>
                          <Button disabled={readOnly} variant="fab" mini aria-label="remove" onClick={this.handleClose}>
                            <Icon className={classes.leftIcon} style={{ color: "#fff" }}>
                              clear
                            </Icon>
                          </Button>
                        </Grid>
                      </Grid>
                    </Modal>
                  </Typography>
                </Grid>
              </Grid>

              <Grid item sm={7} md={8} lg={8}>
                <Grid container direction="row">
                  {plans.map((plan, _idx) => {
                    let planConfig = category.plansConfiguration.find(item => item.code === plan.code);
                    let tier = planConfig && planConfig.tier ? planConfig.tier : "";
                    let planCode = plansCode.find(p => p.value === plan.code);
                    let label = (planCode && planCode.label) || plan.code;
                    let sCode = (planCode && planCode.sCode) || plan.code;

                    let isPlanNotSelected = !planConfig || Util.isEmpty(planConfig.tier);
                    let enableDependent = planConfig && planConfig.enableDependent;
                    let isWwc = planConfig && planConfig.wwc ? planConfig.wwc : false;
                    let isWpec = planConfig && planConfig.wpec ? planConfig.wpec : false;
                    let isWwcNWpec = planConfig && planConfig.wwcNwpec ? planConfig.wwcNwpec : false;

                    if (plan.type == "core" && plan.show) {
                      return (
                        <Grid item sm={12} md={6} lg={4} key={_idx}>
                          <Grid container direction="column" style={{ whiteSpace: "normal" }}>
                            <Grid item>
                              <Typography className={classes.planTitle}>
                                {label} {this.errorIndicator(planConfig.showError)}
                              </Typography>
                              {sCode}
                            </Grid>

                            <Grid item>
                              <Select
                                value={tier}
                                // className={classes.selectField}
                                disabled={readOnly}
                                onChange={handleInputChange(id, plan, "tier")}
                                inputProps={{
                                  name: plan.code,
                                  id: plan.code,
                                  disabled: (plan.attributes && plan.attributes.disabled) || readOnly
                                }}
                                style={{ width: "98%" }}
                              >
                                <MenuItem key={null} value={""} />
                                {plan.tiers.map((tier, _tierIdx) => {
                                  let displayPrimary = <span className={classes.planTier}>{"Plan " + tier}</span>;
                                  let displaySecondary = (
                                    <span className={classes.planTierInfo}>
                                      {plan.tierDescriptions ? plan.tierDescriptions[_tierIdx].descriptions[0] : ""}
                                    </span>
                                  );

                                  return (
                                    <MenuItem key={_tierIdx} value={tier}>
                                      <ListItemText
                                        primary={displayPrimary}
                                        secondary={displaySecondary}
                                        style={{ whiteSpace: "normal" }}
                                      />
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </Grid>

                            {Util.isGFWM(plan.code) ? (
                              <Grid item>
                                <FormControl
                                  key={_idx}
                                  className={classes.formControlPlans}
                                  disabled={readOnly}
                                  style={{ width: "98%" }}
                                >
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        id={plan.code}
                                        className={classes.switchField}
                                        disabled={readOnly || isPlanNotSelected || isWpec || isWwcNWpec}
                                        name={plan.code}
                                        checked={isWwc}
                                        onChange={handleInputChange(id, plan, "wwc")}
                                        classes={{
                                          checked: classes.switchChecked,
                                          bar: classes.switchBar
                                        }}
                                      />
                                    }
                                    label="Worldwide coverage"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        id={plan.code}
                                        className={classes.switchField}
                                        disabled={readOnly || isPlanNotSelected || isWwc || isWwcNWpec}
                                        name={plan.code}
                                        checked={isWpec}
                                        onChange={handleInputChange(id, plan, "wpec")}
                                        classes={{
                                          checked: classes.switchChecked,
                                          bar: classes.switchBar
                                        }}
                                      />
                                    }
                                    label="Waiver of Waiting Period for Pre-Existing Condition"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        id={plan.code}
                                        className={classes.switchField}
                                        disabled={readOnly || isPlanNotSelected || isWwc || isWpec}
                                        name={plan.code}
                                        checked={isWwcNWpec}
                                        onChange={handleInputChange(id, plan, "wwcNwpec")}
                                        classes={{
                                          checked: classes.switchChecked,
                                          bar: classes.switchBar
                                        }}
                                      />
                                    }
                                    label="Worldwide Coverage with Waiver of Waiting Period for Pre-Existing Condition"
                                  />
                                </FormControl>
                              </Grid>
                            ) : (
                              <></>
                            )}

                            {plan.enableDependent ? (
                              <Grid item>
                                <FormControl
                                  key={_idx}
                                  className={classes.formControlPlans}
                                  disabled={readOnly}
                                  style={{ width: "98%" }}
                                >
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        id={plan.code}
                                        className={classes.switchField}
                                        disabled={readOnly || isPlanNotSelected}
                                        name={plan.code}
                                        checked={enableDependent}
                                        onChange={handleInputChange(id, plan, "enableDependent")}
                                        classes={{
                                          checked: classes.switchChecked,
                                          bar: classes.switchBar
                                        }}
                                      />
                                    }
                                    label="Include Dependants"
                                  />
                                </FormControl>
                              </Grid>
                            ) : (
                              <Grid item>
                                <FormControl key={_idx} className={classes.formControlPlans} disabled={readOnly} />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </Grid>

              <Grid item sm={1} md={1} lg={1} />

              {/* Employee Plans For Supplementry =========================== */}
              {/* Dependants Plans =============================== */}

              {plans.filter(plan => plan.type === "supplementary" && plan.show).length == 0 ? (
                ""
              ) : (
                <>
                  <Grid item sm={1} md={1} lg={1} />

                  <Grid item sm={3} md={2} lg={2}>
                    <Grid item>
                      <Typography className={classes.labelTitle}>Select Supplementry Plans</Typography>
                      <Typography>Availability of supplementary plans are based on your core plan selection</Typography>
                    </Grid>
                  </Grid>

                  <Grid item sm={7} md={8} lg={8}>
                    <Grid container direction="row">
                      {plans.map((plan, _idx) => {
                        let planConfig = category.plansConfiguration.find(item => item.code === plan.code);
                        let tier = planConfig && planConfig.tier ? planConfig.tier : "";
                        let planCode = plansCode.find(p => p.value === plan.code);
                        let label = (planCode && planCode.label) || plan.code;
                        let sCode = (planCode && planCode.sCode) || plan.code;

                        let isPlanNotSelected = !planConfig || Util.isEmpty(planConfig.tier);
                        let enableDependent = planConfig && planConfig.enableDependent;

                        let gamrPlanConfig = category.plansConfiguration.find(item => item.code == "GAMR");

                        if (plan.type != "core" && plan.show) {
                          return (
                            <Grid item sm={12} md={6} lg={4} key={_idx}>
                              <Grid container direction="column">
                                <Grid item>
                                  <Typography className={classes.planTitle}>
                                    {label} {this.errorIndicator(planConfig.showError)}
                                  </Typography>
                                  <Typography>{sCode}</Typography>
                                </Grid>

                                <Grid item>
                                  <Select
                                    value={tier}
                                    className={classes.selectField}
                                    disabled={readOnly}
                                    onChange={handleInputChange(id, plan, "tier")}
                                    inputProps={{
                                      name: plan.code,
                                      id: plan.code,
                                      disabled: (plan.attributes && plan.attributes.disabled) || readOnly
                                    }}
                                    style={{ width: "98%" }}
                                  >
                                    <MenuItem key={null} value={""}></MenuItem>
                                    {plan.tiers.map((tier, _tierIdx) => {
                                      let displayPrimary = <span className={classes.planTier}>{"Plan " + tier}</span>;
                                      let displaySecondary = (
                                        <span className={classes.planTierInfo}>
                                          {plan.tierDescriptions ? plan.tierDescriptions[tier - 1].descriptions[0] : ""}
                                        </span>
                                      );

                                      return (
                                        <MenuItem key={_tierIdx} value={tier} style={{ whiteSpace: "normal" }}>
                                          <ListItemText
                                            primary={displayPrimary}
                                            secondary={displaySecondary}
                                            style={{ whiteSpace: "normal" }}
                                          />
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </Grid>

                                {plan.code == "GADD" ? (
                                  <Grid item>
                                    <FormControl
                                      key={_idx}
                                      className={classes.formControlPlans}
                                      disabled={readOnly}
                                      style={{ width: "98%" }}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            id={plan.code}
                                            className={classes.switchField}
                                            disabled={readOnly || isPlanNotSelected}
                                            name={plan.code}
                                            checked={gamrPlanConfig.tier ? true : false}
                                            onChange={handleInputChange(id, plan, "enableGAMR")}
                                            classes={{
                                              checked: classes.switchChecked,
                                              bar: classes.switchBar
                                            }}
                                          />
                                        }
                                        label="Group Accidental Medical Reimbursement (GAMR)"
                                      />
                                    </FormControl>
                                  </Grid>
                                ) : (
                                  ""
                                )}

                                {plan.showDependent && plan.enableDependent ? (
                                  <Grid item>
                                    <FormControl
                                      key={_idx}
                                      className={classes.formControlPlans}
                                      disabled={readOnly}
                                      style={{ width: "98%" }}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            id={plan.code}
                                            className={classes.switchField}
                                            disabled={readOnly || isPlanNotSelected}
                                            name={plan.code}
                                            checked={enableDependent}
                                            onChange={handleInputChange(id, plan, "enableDependent")}
                                            classes={{
                                              checked: classes.switchChecked,
                                              bar: classes.switchBar
                                            }}
                                          />
                                        }
                                        label="Include Dependants"
                                      />
                                    </FormControl>
                                  </Grid>
                                ) : (
                                  ""
                                )}

                                <Grid item>
                                  <FormControl key={_idx} className={classes.formControlPlans} disabled={readOnly} />
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        }
                      })}
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid item sm={1} md={1} lg={1} />
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

const styles = theme => ({
  paper: {
    position: "relative"
  },
  companyNameField: {
    [theme.breakpoints.up("sm")]: {
      width: "35%"
    },
    [theme.breakpoints.down("sm")]: {
      width: "70%"
    }
  },
  root: {
    width: "100%",
    fontSize: "0.85em",
    paddingBottom: theme.spacing.unit
  },
  expStyle: {
    height: "20px"
  },
  employeeTitle: {
    color: "red",
    fontWeight: "bold"
  },
  labelTitle: {
    fontWeight: "bold",
    fontFamily: "OpenSans"
  },
  planTitle: {
    fontWeight: "bold"
  },
  planTier: {
    fontWeight: "bold"
  },
  planTierInfo: {
    color: "grey"
  },
  viewPlanDetails: {
    color: "red"
  },
  container: {
    overflowX: "auto",
    marginLeft: theme.spacing.unit * 2.0,
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit
  },
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between"
  },
  wrapper: {
    paddingTop: theme.spacing.unit * 0.8,
    paddingBottom: theme.spacing.unit * 0.8,
    display: "flex"
  },
  textField: {
    marginRight: theme.spacing.unit
  },
  selectField: {
    marginRight: theme.spacing.unit,
    whiteSpace: "normal"
  },
  switchInput: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  switchChecked: {
    color: theme.palette.primary.main,
    "& + $switchBar": {
      backgroundColor: theme.palette.primary.main
    }
  },
  switchDisabled: {},
  switchBar: {},
  labelTitlePlans: {
    padding: theme.spacing.unit * 2,
    paddingLeft: 0
  },
  formControlPlans: {
    width: "90px"
  },
  img: {
    marginLeft: -5
  },
  imgContainer: {
    color: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    position: "absolute",
    right: 0,
    bottom: 0,
    zIndex: 100
  },
  popupTitle: {
    fontWeight: 700,
    fontFamily: "OpenSans",
    bottom: "0",
    width: "100%",
    height: "40px"
  },
  popup: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    height: "85%",
    backgroundColor: theme.background.color,
    // backgroundColor: theme.palette.default.main,
    color: theme.palette.default.fontColor,
    p: 4,
    overflowY: "auto"
  },
  containerPopup: {
    marginLeft: theme.spacing.unit * 5.0,
    marginRight: theme.spacing.unit * 5.0,
    paddingTop: theme.spacing.unit * 5.0,
    paddingBottom: theme.spacing.unit * 5.0
    // display: "inline-block"
  },
  containerToolbar: {
    justifyContent: "flex-start",
    width: "100%",
    ...theme.extended.minHeight(55)
  },
  leftActionsToolbar: {
    display: "flex",
    flexDirection: "row",
    marginLeft: -20
  },
  actionsToolbar: {
    display: "flex",
    flexDirection: "row"
  },
  iconError: {
    backgroundColor: theme.colors.secondary.white,
    color: theme.colors.error
  }
});

function mapStateToProps(state) {
  return {
    //Keep this even though we are not using this in the code for now, it keeps the props updated, not sure why...though.........
    quote: state.quote,
    document: state.document
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(quoteActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(Category)));
