import React, { createContext, useContext, useState } from "react";

const FormContext = createContext(null);

export const FormProvider = ({ children }) => {
    const [formState, setFormState] = useState({});

    const handleForm = (formState, name) => {
        setFormState((prev) => ({
            ...prev,
            [name]: {
                values: formState.values,
                errors: formState.errors,
            }
        }))
    }

    return (
        <FormContext.Provider value={{ form: formState, handleForm }}>
            {children}
        </FormContext.Provider>
    )
}

export const useFormContext = () => useContext(FormContext);

export const withFormContext = (Component) => (props) => (
    <FormContext.Consumer>
        {(context) => <Component {...props} formContext={context} />}
    </FormContext.Consumer>
)