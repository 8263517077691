import { useEffect, useMemo } from "react";
import SimpleControlDialog from "../../../../../components/SimpleControlDialog";
import { renderTextField } from "../../../../../components/CustomReduxFormComponent";
import Typography from "@mui/material/Typography";
import useOtp from "../../hooks/useOtp";
import { typographyRootStyle } from "../../types/typographyRootStyle";
import { Form, Field } from "react-final-form";

const required = value => (value ? undefined : "Required");

const OtpEmail = ({
  txId,
  show,
  description,
  closeHandler,
  submitHandler,
  submitError,
  submitErrorMessage,
  anotherWayHandler,
  closeButtonDisabled
}) => {
  let formApi;
  const classes = useMemo(() => ({
    title: {
      paddingBottom: "1rem",
      fontWeight: "500"
    }
  }), []);

  useEffect(() => {
    formApi?.reset();
  }, [show, formApi]);

  const submit = values => {
    submitHandler(values.otp);
  };

  const { otpState, sendOtp } = useOtp();

  useEffect(() => {
    if (show) {
      if (otpState?.previousOtpMethod !== "EMAIL") {
        console.log("SENDING EMAIL@@@");
        sendOtp("EMAIL", txId);
      }
    }
  }, [show]);

  return (
    <>
      <Form 
        onSubmit={submit}
        render={({ handleSubmit, form, valid }) => {
          formApi = form;
          return (
            <SimpleControlDialog
              show={show}
              title="Enter OTP"
              description={description}
              onClose={closeHandler}
              closeHandler={anotherWayHandler}
              valid={valid}
              onSubmit={handleSubmit}
              okButtonText="Submit"
              closeButtonText="Try Another Way"
              showCloseButton={true}
              closeButtonDisabled={closeButtonDisabled}
            >
              {otpState?.errorEmail && (
                <div variant="caption" style={typographyRootStyle}>
                  {otpState?.errorEmail}
                </div>
              )}
              {!otpState?.errorEmail && otpState?.sentChannel === "EMAIL" && (
                <Typography variant="body2" className={classes.title}>
                  A OTP was just sent to your email address.
                </Typography>
              )}
              <Field
                name="otp"
                autoFocus
                margin="dense"
                component={renderTextField}
                autoComplete="off"
                label="OTP"
                type="text"
                validate={required}
              />
              {submitError && (
                <div variant="caption" style={typographyRootStyle}>
                  {submitErrorMessage}
                </div>
              )}
            </SimpleControlDialog>
          )
        }}
      />
    </>
  );
};

export default OtpEmail;
