import * as actionTypes from "./types";
import PolicyService from "../services/policy.service";
import util from '../utils/Util';

export const policyEmployeeActions = {
  getMyEmployeesInformation,
  getCategories,
  getDependent,
  exportFCPolicyEmployee
};

function getMyEmployeesInformation() {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({
      type: actionTypes.POLICY_GET_MY_EMPLOYEES,
      payload: state.policyDetails.policy.employees
    });
  };
}

function getCategories() {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({
      type: actionTypes.POLICY_MY_EMPLOYEE_LOAD_CATEGORY,
      payload: state.policyDetails.policy.employeeCategories
    });
  };
}

function getDependent(dependentData) {
  return dispatch => {
    dispatch({
      type: actionTypes.POLICY_MY_EMPLOYEE_ALL_DEPENDENT,
      payload: dependentData
    });
  };
}

function exportFCPolicyEmployee() {
  return (dispatch, getState) => {
    const policyNo = getState().policyDetails.policy.policyNo;
    return PolicyService.exportFCPolicyEmployee(policyNo).then(
      response => {
        if (response.success) {
          util.downloadFile(response.data, response.filename || `Employees_${policyNo}.xlsx`);
        }
        return Promise.resolve(response);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}
