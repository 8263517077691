import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";
import { Form, Field } from "react-final-form";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Util from "../../utils/Util.js";

import assets from "../../assets";
import { userActions, appActions } from "../../actions";
import { renderTextField } from "../../components/CustomReduxFormComponent";
import SimpleMessageDialog from "../../components/SimpleMessageDialog";
import OtpDialog from "./OtpDialog";
import ForgotPasswordDialog from "./ForgotPasswordDialog";

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showOTP: false,
      isGettingOTP: false,
      isGettingOTPError: false,
      submitOTPError: false,
      submitSuccessfully: false,
      isCodeValid: false,
      showOTPDesc: false,
      isInvalidCharacter: false
    };
  }

  componentWillMount() {
    //reset user state;
    this.props.app.reset();
    // Check if invitation/reset code has expired
    this.props.user.getResetLinkValidity(this.getCode()).then(
      resp => {
        if (resp) {
          console.log("Check if invitation/reset code has expired", resp);
          this.setState({ isCodeValid: resp.data });
        }
      },
      error => {
        if (error.code === 404) {
          this.setState({ isCodeValid: false });
        }
      }
    );
  }

  handleCloseOtpDialog(error = null) {
    if (error != null) {
      this.handlePutResetPasswordOTPFail(error);
    }
    this.setState({ showOTP: false, submitOTPError: false });
  }

  handleCloseErrorOtpDialog = () => {
    this.setState({ isGettingOTPError: false });
  };

  submit = values => {
    this.setState({ isGettingOTP: true });
    this.props.user.getResetPasswordOTP(this.getCode()).then(
      resp => {
        if (resp.data != null) {
          this.setState({
            showOTP: true,
            isGettingOTP: false,
            username: resp.data.username,
            password: values.password,
            confirmPassword: values.confirmPassword,
            isInvalidCharacter: false,
            errorMessageInvalidCharacter: "",
            submitOTPErrorMessage: "",
            submitOTPError: false
          });
          if (resp.data.otp_response != null) {
            this.setState({ otpRes: resp.data.otp_response, showOTPDesc: true });
          } else {
            this.setState({ otpRes: "", showOTPDesc: false });
          }
        }
      },
      error => {
        if (error.code === 404) error["customMessage"] = "The link has expired";
        else {
          this.setState({ isGettingOTP: false }, () => {
            this.handleGetResetPasswordOTPError(error);
          });
        }
      }
    );
  };

  getCode() {
    const params = new URLSearchParams(this.props.location.search);
    return params.get('token');
  }

  getEncryptedPassword = (username, password) => {
    return this.props.user.getRSA(username, password).then(
      resp => {
        if (resp.success) {
          //global object is needed for encryption.
          const randomKey = resp.data.random_key;
          const txnId = resp.data.tx_id;

          var myObjectInstance = new PACSCryptography(resp.data.public_key);
          var encryptedPW = myObjectInstance.encryptPassword(this.state.password);

          this.setState({
            randomKey,
            txnId
          });
          return Promise.resolve(encryptedPW);
        }
      },
      error => {
        console.log("RSA Error : ", error);
        if (error.code !== 500) {
          this.setState({
            rsaErrorMessage: error.message || error.defaultMessage
          });
        }
        return Promise.reject(error);
      }
    );
  };

  handleSubmitOTP(otp) {
    const { password, confirmPassword } = this.state;

    this.getEncryptedPassword(this.state.username.toLowerCase(), password).then(
      encrypted => {
        this.props.user
          .putResetPasswordOTP({
            otp,
            code: this.getCode(),
            new_password: encrypted,
            confirm_password: encrypted,
            randomKey: this.state.randomKey,
            txnId: this.state.txnId
          })
          .then(
            resp => {
              this.setState({ submitSuccessfully: true }, () => {
                this.handleCloseOtpDialog();
              });
            },
            error => {
              this.setState(() => {
                this.handlePutResetPasswordOTPFail(error);
              });
            }
          );
      },
      error => {
        this.setState({
          submitOTPError: true,
          submitOTPErrorMessage: error.customMessage || error.defaultMessage
        });
      }
    );
  }

  handleRedirectToLogin = () => {
    this.props.history.push("/");
  };

  handleOpenCloseForgotPassword() {
    this.setState({ showForgotPassword: !this.state.showForgotPassword });
  }

  handleGetResetPasswordOTPError(error) {
    error.customMessage = Util.validateOtpResetPassword(error.message);
    this.setState({
      showOTP: false,
      isInvalidCharacter: true,
      errorMessageInvalidCharacter: error.customMessage || error.defaultMessage
    });
  }

  handlePutResetPasswordOTPFail(error) {
    // Check if this error code is closed the OTP or not
    Util.errorCodeCloseOtpDialog(error);
    // Get exact error message of reset pwd screen
    error.customMessage = Util.validateOtpResetPassword(error.message);
    if (error.isCloseOtpDialog) {
      this.setState({
        showOTP: false,
        isInvalidCharacter: true,
        errorMessageInvalidCharacter: error.customMessage || error.defaultMessage
      });
    } else {
      this.setState({
        submitOTPError: true,
        submitOTPErrorMessage: error.customMessage || error.defaultMessage
      });
    }
  }

  render() {
    const { intl, classes } = this.props;
    const {
      showOTP,
      isGettingOTP,
      isGettingOTPError,
      submitOTPError,
      errorMessage,
      submitOTPErrorMessage,
      showForgotPassword,
      submitSuccessfully,
      isInvalidCharacter,
      errorMessageInvalidCharacter
    } = this.state;
    const images = assets.images;
    if (this.state.isCodeValid) {
      return (
        <div className={classes.root}>
          <div>
            <img src={images.pru_logo} className="App-logo" alt="logo" />
          </div>
          <Typography variant="subheading" gutterBottom>
            {intl.formatMessage({ id: "reset.password" })}
          </Typography>
          <div className={classes.formContainer}>
            {isInvalidCharacter ? (
              <div className={classes.alignCenterDiv}>
                <Typography variant="subheading" className={classes.errorMessage}>
                  {errorMessageInvalidCharacter}
                  <br />
                </Typography>
              </div>
            ) : (
              " "
            )}
            <Form
              onSubmit={this.submit}
              validate={validate}
              render={({ handleSubmit, valid, pristine, form }) => {
                const formState = form.getState();
                const anyTouched = Object.keys(formState.touched || {}).length > 0;
                return (
                  <form onSubmit={handleSubmit}>
                    <Field
                      disabled={isGettingOTP}
                      name="password"
                      margin="dense"
                      component={renderTextField}
                      autoComplete="[off]"
                      label={intl.formatMessage({ id: "login.password" })}
                      type="password"
                    />
                    <Field
                      disabled={isGettingOTP}
                      name="confirmPassword"
                      margin="dense"
                      component={renderTextField}
                      autoComplete="[off]"
                      label={intl.formatMessage({ id: "login.confirm.password" })}
                      type="password"
                    />
                    <Typography
                      variant="caption"
                      gutterBottom
                      className={classes.passwordResetRulesMessage}
                    >
                      {intl.formatMessage({ id: "reset.password.hint" })}
                    </Typography>

                    {/* Fade In spinner when getting OTP */}
                    <Fade
                      in={isGettingOTP}
                      style={{
                        transitionDelay: isGettingOTP ? "40ms" : "0ms"
                      }}
                      unmountOnExit
                    >
                      <CircularProgress />
                    </Fade>

                    <br />

                    <div className={classes.alignCenterDiv}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        disabled={pristine || !valid || !anyTouched || isGettingOTP}
                      >
                        Confirm
                      </Button>
                    </div>
                  </form>
                 )
               }}
             />
          </div>

          {/* OTP Dialog */}
          <OtpDialog
            show={showOTP}
            submitError={submitOTPError}
            description={
              this.state.showOTPDesc ? (
                <span>
                  An OTP has been sent to your registered Email Id and registered Mobile Number. <br />
                  Email Id: <b>{this.state.otpRes.email_id}</b>
                  <br />
                  Mobile Number: <b>{this.state.otpRes.contact_no}</b>
                  <br />
                </span>
              ) : (
                <span>
                  An OTP has been sent to your email address.
                  <br /> Enter OTP to reset password.
                </span>
              )
            }
            submitErrorMessage={submitOTPErrorMessage}
            submitHandler={this.handleSubmitOTP.bind(this)}
            closeHandler={this.handleCloseOtpDialog.bind(this)}
          />

          {/*Error Message*/}
          <SimpleMessageDialog
            type="error"
            name="error-submit"
            title="Error"
            description={errorMessage}
            open={isGettingOTPError}
            closeHandler={this.handleCloseErrorOtpDialog}
            closeButtonText="Close"
          />

          {/* Succesfully Dialog */}
          <SimpleMessageDialog
            isHtml={true}
            type="success"
            name="reset-success"
            title="Reset Password Success"
            description="Password has been changed successfully. <br/> You can login with your new password"
            open={submitSuccessfully}
            closeHandler={this.handleRedirectToLogin}
            closeButtonText="Login"
          />
        </div>
      );
    } else {
      return (
        <div className={classes.root}>
          <div>
            <img src={images.pru_logo} className="App-logo" alt="logo" />
          </div>
          <Typography variant="subheading" gutterBottom>
            {intl.formatMessage({ id: "expired.invitation.link" })}
          </Typography>
          <br />
          <Typography variant="subheading" gutterBottom>
            {intl.formatMessage({ id: "click.to.reset" })}
          </Typography>
          <br />
          <Button
            className={classes.forgotPassword}
            variant="raised"
            color="primary"
            onClick={this.handleOpenCloseForgotPassword.bind(this)}
          >
            Forgot password
          </Button>

          <ForgotPasswordDialog
            show={showForgotPassword}
            forgotPassword={this.props.user.forgotPassword}
            closeHandler={this.handleRedirectToLogin}
            open={true}
          />
        </div>
      );
    }
  }
}

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    "& > div": {
      margin: theme.spacing.unit * 2
    }
  },
  passwordResetRulesMessage: {
    textAlign: "left",
    margin: "20px 0",
    whiteSpace: "pre-line"
  },
  button: {
    margin: theme.spacing.unit * 2
  },
  formContainer: {
    maxWidth: "250px"
  },
  successIcon: {
    fontSize: 50,
    marginBottom: 20,
    color: "green"
  },
  errorMessage: {
    marginBottom: theme.spacing.unit,
    color: theme.palette.error.main
  },
  alignCenterDiv: {
    textAlign: "center"
  }
});

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    user: bindActionCreators(userActions, dispatch),
    app: bindActionCreators(appActions, dispatch)
  };
}

const validate = values => {
  const errors = {};

  if (!values.password) {
    errors.password = "Required";
  } else if (values.password.length < 8) {
    errors.password = "At least 8 characters";
  } else if (!/^(?=.*[0-9])/i.test(values.password)) {
    errors.password = "A digit must occur at least once";
  } else if (!/^(?=.*[A-Z]).*$/.test(values.password)) {
    errors.password = "An upper case letter must occur at least once";
  } else if (!/^(?=.*[a-z]).*$/.test(values.password)) {
    errors.password = "A lower case letter must occur at least once";
  } else if (!/^\S+$/i.test(values.password)) {
    errors.password = "No whitespace allowed";
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = "Required";
  } else if (values.confirmPassword !== values.password) {
    errors.confirmPassword = "Pssword not match";
  }

  return errors;
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    withStyles(styles, { withTheme: true })(ResetPassword)
  )
);
