import React, { Component } from "react";
import { withStyles } from "@mui/styles";

import ActionToolbarStep0 from "./ActionToolbarStep0";
import ActionToolbarStep1 from "./ActionToolbarStep1";
import ActionToolbarStep2 from "./ActionToolbarStep2";

import classNames from "classnames";

class ActionFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderToolbar(activeStep, hasEdits) {
    switch (activeStep) {
      case 0:
        return <ActionToolbarStep0 activeStep={activeStep} handlerNextStep={this.props.handlerNextStep} />;
      case 1:
        return <ActionToolbarStep1 activeStep={activeStep} handlerNextStep={this.props.handlerNextStep} />;
      case 2:
        return (
          <ActionToolbarStep2
            activeStep={activeStep}
            hasEdits={hasEdits}
            handlerNextStep={this.props.handlerNextStep}
            form={this.state.form}
          />
        );
      case 3:
        return (
          <ActionToolbarStep2
            activeStep={activeStep}
            handlerNextStep={this.props.handlerNextStep}
            valid={this.props.valid}
          />
        );
      default:
        return;
    }
  }
  render() {
    const { classes, activeStep, navDrawerOpen, hasEdits } = this.props;

    return (
      <div className={classNames(classes.root, navDrawerOpen && classes.menuShow)}>
        {this.renderToolbar(activeStep, hasEdits)}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    width: `calc(100% - ${theme.miniLeftDrawer.widthClosed}px)`,
    backgroundColor: "#f2f2f2",
    position: "fixed",
    bottom: 0,
    right: 0,
    // Fixed issue on Safari. The content in back of footer was overlapped
    zIndex: 1,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuShow: {
    width: `calc(100% - ${theme.miniLeftDrawer.width}px)`
  }
});

export default withStyles(styles, { withTheme: true })(ActionFooter);
