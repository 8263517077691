import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { bindActionCreators } from "redux";

import SimpleMessageDialog from "../../../components/SimpleMessageDialog";
import RenewalListingTable from "./Dashboard/RenewalListingTable";
import RenewalSelectRequest from "./Dashboard/RenewalSelectRequest";
import RenewalUploadForms from "./Dashboard/RenewalUploadForms";

import RenewalSubmissionListingTable from "./Dashboard/RenewalSubmissionListingTable";
import { renewalActions } from "../../../actions";
import { documentActions } from "../../../actions";
import PolicyRenewalSteps from "./PolicyRenewalSteps";

import { withStyles } from "@mui/styles";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { Typography } from "@mui/material";
import LoadingPopup from "../../../components/LoadingPopup";
import RenewalSubmission from "./Dashboard/RenewalSubmission";
import RenewalSubmittedDocuments from "./Dashboard/RenewalSubmittedDocuments";

class Renewal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMessageDialog: false,
      errorMessage: "",
      searchInputValue: "",
      currentPageNo: 0,
      activeStep: 1,
      selectedPolicy: this.props.policyRenew.policy_no == "" ? null : this.props.policyRenew.policy_no,
      renewalType: this.props.policyRenew.renewal_type == "" ? null : this.props.policyRenew.renewal_type,
      renewalTypeOptions: [],
      isRenewalBtnDiabled: true,
      isSubmitBtnDiabled: true,
      files: [],
      errors: {},
      renewals: [], // Placeholder for renewal data
      renewalSubmissions: [], // Placeholder for renewal submission data
      renewalPage: "",
      hasStepSet: false,
      previousRenewalType: "", //store the lastes selected renewal type
      previousRenewalId: "", //store the lastest existing_renewal_id
      previousDocumentData: [], // keeping the already selected documents on step3
      checkSubmitButton: false, //using this to check submit button disable condition
      checkNextButton: false //using this to checknext button disable condition
    };
  }

  getStepFromQuery = () => {
    const query = new URLSearchParams(this.props.location.search);
    let id = query.get("id");
    if (id != 1 && this.state.hasStepSet) {
      this.setState({
        hasStepSet: false
      });
      // let path = `${this.props.location.pathname}?id=${this.state.activeStep + 1}`
      // this.props.history.push(path);
    }
    if (id == 1 && !this.state.hasStepSet) {
      this.setState({
        activeStep: 1,
        hasStepSet: true
      });
    }
  };

  setStepFromQuery = () => {
    const query = new URLSearchParams(this.props.location.search);
    let id = query.get("id");
    if (id && !this.state.hasStepSet) {
      this.setState({
        activeStep: parseInt(id)
        // hasStepSet: true
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.document.renewalFiles.length && !this.state.checkSubmitButton) {
      this.setIsSubmitBtnDisabled();
      this.setState({
        checkSubmitButton: true
      });
    }
    if (this.state.selectedPolicy && !this.state.checkNextButton) {
      this.setState({
        isRenewalBtnDiabled: false,
        checkNextButton: true
      });
    }
    this.getStepFromQuery();
  }

  componentWillMount() {
    this.setStepFromQuery();
    const { policyRenew } = this.props;
    if (policyRenew.id) this.props.documentActions.getRenewalFiles(policyRenew.id);
    this.validateForms();
  }

  componentDidMount() {
    this.state.renewalPage = "step0";
    const { policyRenew } = this.props;
    if (this.state.activeStep == 2 || this.state.activeStep == 3) {
      if (["PF2", "PF3", "PTF"].includes(policyRenew.contract_type)) {
        this.setState({
          renewalTypeOptions: [
            {
              name: "Renew without changes",
              value: "RENEW_WITHOUT_CHG"
            },
            {
              name: "Renew with changes",
              value: "RENEW_WITH_CHG"
            }
          ]
        });
      } else {
        this.setState({
          renewalTypeOptions: [
            {
              name: "Renew",
              value: "RENEW"
            }
          ]
        });
      }
    }
    if (policyRenew.id) {
      this.props.documentActions.getRenewalFiles(policyRenew.id).then(document => {
        this.setState({
          previousDocumentData: [...document.value.data.data]
        });
      });
    }

    this.props.getRenewPolicies().then(res => {
      this.state.renewalPage = "step1";
      this.setState({
        renewals: [...this.props.renewalPolicies.pendingList],
        renewalSubmissions: [...this.props.renewalPolicies.submmitedList]
      });
    });
    // this.props.getRenewSubmissionList();

    // Simulate fetching data
    const { activeStep, selectedPolicy, renewalType } = this.state;
    if (activeStep === 1) {
      this.setState({
        isRenewalBtnDiabled: selectedPolicy === null
      });
    } else if (activeStep === 2) {
      this.setState({
        isRenewalBtnDiabled: renewalType === null
      });
    }

    this.setState({
      renewals: [...this.props.renewalPolicies.pendingList],
      renewalSubmissions: [...this.props.renewalPolicies.submmitedList]
    });
  }

  handleErrorInput = errorMessage => {
    this.setState({
      errorMessage: errorMessage,
      openMessageDialog: true
    });
  };

  handleCloseMessageDialog = () => {
    this.setState({ errorMessage: "", openMessageDialog: false });
  };

  handleRadioChange = (event, details) => {
    if (this.state.activeStep === 1) {
      this.setState({
        selectedPolicy: event.target.value,
        isRenewalBtnDiabled: false,
        renewalType: details?.renewal_type
      });
      this.props.setRenewalType({
        renewal_type: details?.renewal_type
      });
      this.props.setRenewal(details);
    } else if (this.state.activeStep === 2) {
      this.setState({ renewalType: event.target.value, isRenewalBtnDiabled: false });
      this.props.setRenewalType({
        renewal_type: event.target.value
      });
      //for manage the documwnts and checkbox
      if (this.props.policyRenew.id) {
        /*calling api to change has_hr_dec_changed status */
        if (event.target.value == "RENEW_WITHOUT_CHG") {
          this.props.setHasHrDecChanged(false);
          let details = this.mapBodyForCreatePolicy(this.props.policyRenew);
          details.status = this.props.policyRenew.status;
          if (this.state.renewalType?.length) {
            this.props.putRenewalPolicy(this.props.policyRenew.id, details);
          }
        }

        if (this.props.document.renewalFiles?.length) {
          /*deleting documents when renewal type change */
          this.props.document.renewalFiles.map(async fileId => {
            await this.props.documentActions
              .deleteRenewalFile(this.props.policyRenew.id, fileId?.encrypted_id)
              .then(res => {
                this.onRemoveFile();
              })
              .catch(error => {});
          });
        }
      }
    }
  };

  handleNext = () => {
    const { policyRenew, renewalPolicies } = this.props;
    if (this.state.activeStep === 1) {
      this.state.renewalPage = "step1";
      //calling document api if policy id is null
      if (this.props.policyRenew?.id) {
        this.props.documentActions.getRenewalFiles(this.props.policyRenew.id).then(document => {
          this.setIsSubmitBtnDisabled();
        });
      }
      if (["PF2", "PF3", "PTF"].includes(policyRenew.contract_type)) {
        this.setState({
          renewalTypeOptions: [
            {
              name: "Renew without changes",
              value: "RENEW_WITHOUT_CHG"
            },
            {
              name: "Renew with changes",
              value: "RENEW_WITH_CHG"
            }
          ]
        });
      } else {
        this.setState({
          renewalTypeOptions: [
            {
              name: "Renew",
              value: "RENEW"
            }
          ]
        });
      }
      this.setState({
        isRenewalBtnDiabled: this.state.renewalType === null || this.state.renewalType === undefined
      });
    } else if (this.state.activeStep === 2) {
      this.setIsSubmitBtnDisabled();
      const details = this.mapBodyForCreatePolicy(policyRenew);
      if (policyRenew.id) {
        this.props.renewalPolicies.pendingList.map(item => {
          if (item.id === policyRenew.id || policyRenew.id == this.state.previousRenewalId) {
            if (
              (policyRenew.renewal_type !== item.renewal_type && this.state.previousRenewalType == "") ||
              (this.state.previousRenewalType && policyRenew.renewal_type !== this.state.previousRenewalType)
            ) {
              details.existing_renewal_id = policyRenew.id;
            } else {
              this.setState({
                activeStep: this.state.activeStep + 1
              });
              //updating the path based on step number
              let path = `${this.props.location.pathname}?id=${this.state.activeStep + 1}`;
              this.props.history.push(path);
            }
          }
        });
      } else {
        this.props
          .createRenewal(details)
          .then(res => {
            this.state.renewalPage = "step2";
            this.props.setRenewalId(res.value.data.data.id);
            this.props.setRenewalStatus("DRAFT");
            this.setState({
              activeStep: this.state.activeStep + 1,
              previousRenewalType: this.state.renewalType,
              previousRenewalId: res.value.data.data.id
            });
            //updating the path based on step number
            if (this.state.activeStep == 2) {
              let path = `${this.props.location.pathname}?id=${this.state.activeStep + 1}`;
              this.props.history.push(path);
            } else {
              let path = `${this.props.location.pathname}?id=${this.state.activeStep}`;
              this.props.history.push(path);
            }
          })
          .catch(err => {
            this.handleErrorInput(err?.message);
          });
      }
      //we were calling the createRenewal api here.
      if (details.existing_renewal_id) {
        this.props
          .createRenewal(details)
          .then(res => {
            this.state.renewalPage = "step2";
            this.props.setRenewalId(res.value.data.data.id);
            this.props.setRenewalStatus("DRAFT");
            this.setState({
              activeStep: this.state.activeStep + 1,
              previousRenewalType: this.state.renewalType,
              previousRenewalId: res.value.data.data.id
            });
            //updating the path based on step number
            let path = `${this.props.location.pathname}?id=${this.state.activeStep}`;
            this.props.history.push(path);
          })
          .catch(err => {
            this.handleErrorInput(err?.message);
          });
      }
    }
    if (this.state.renewalPage?.length && this.state.activeStep == 1) {
      this.setState({
        activeStep: this.state.activeStep + 1
      });
      let path = `${this.props.location.pathname}?id=${this.state.activeStep + 1}`;
      this.props.history.push(path);
    }
    this.state.renewalPage = "";
  };

  renewTypeChanged = () => {};

  handleSubmit = () => {
    this.validateForms();
    if (this.isValid().valid) {
      this.props.setRenewalStatus("SUBMITTED");
      let details = this.mapBodyForCreatePolicy(this.props.policyRenew);
      details.status = "SUBMITTED";
      this.props
        .putRenewalPolicy(this.props.policyRenew.id, details)
        .then(res => {
          this.setState({
            activeStep: this.state.activeStep + 1
          });
          let path = `${this.props.location.pathname}?id=${this.state.activeStep + 1}`;
          this.props.history.push(path);
        })
        .catch(err => {
          this.handleErrorInput(err?.message);
        });
    }
  };

  setIsSubmitBtnDisabled = isChecked => {
    this.setState({
      isSubmitBtnDiabled: !this.isValid(isChecked).valid
    });
  };

  onRemoveFile = () => {
    this.setIsSubmitBtnDisabled();
  };

  checkActiveStep = () => {
    if (this.state.activeStep == 2) {
      this.state.renewalPage = "step1";
    } else if (this.state.activeStep == 3) {
      this.state.renewalPage = "";
    }
  };

  handleSave = () => {
    if (this.state.activeStep != 3) {
      this.props.setRenewalStatus("DRAFT");
    }
    let details = this.mapBodyForCreatePolicy(this.props.policyRenew);
    details.status = this.props.policyRenew.status;
    if (this.state.renewalType?.length) {
      this.props.putRenewalPolicy(this.props.policyRenew.id, details);
    }
    this.setState({
      activeStep: this.state.activeStep - 1
    });
    this.state.isRenewalBtnDiabled = false;
    let path = `${this.props.location.pathname}?id=${this.state.activeStep - 1}`;
    this.props.history.push(path);
    this.checkActiveStep();
  };

  mapBodyForCreatePolicy(policy_renew) {
    return {
      company_name: policy_renew.company_name,
      policy_no: policy_renew.policy_no,
      expiry_date: policy_renew.expiry_date,
      renewal_type: policy_renew.renewal_type,
      contract_type: policy_renew.contract_type,
      has_hr_dec_changed: policy_renew.has_hr_dec_changed
      // status: policy_renew.status
    };
  }

  getPageTitle() {
    switch (this.state.activeStep) {
      case 1:
        return "Policy Renewal";
      case 2:
        return "Submit a request for Renewal";
      case 3:
        return "Submit a request for Renewal";
      case 4:
        return "Your Request for Renewal has been successfully submitted";
      case 5:
        return "Request for Renewal";
      default:
        return "";
    }
  }

  getBreadCrumbTitle() {
    switch (this.state.activeStep) {
      case 1:
        return "Renewal";
      case 2:
        return "RequestForRenewal";
      case 3:
        return "RequestForRenewal";
      case 4:
        return "RequestForRenewal";
      case 5:
        return "RequestForRenewal";
      default:
        return "";
    }
  }

  getPrefix(renewalType) {
    if (renewalType === "RENEW_WITHOUT_CHG") {
      return "RWOC_";
    } else if (renewalType === "RENEW_WITH_CHG") {
      return "RWC_";
    } else {
      return "RWCC_";
    }
  }

  setUploadFormErrors(error) {
    const { errors } = this.state;
    this.setState(prevState => {
      const updatedErrors = {
        ...prevState.errors,
        ...error
      };
      return {
        errors: updatedErrors
      };
    });
  }

  validateForms() {
    const { errors, renewalType } = this.state;
    // get all file types

    const errorMessages = {
      LETTER: "Renewal invitation letter is required",
      HR_DEC: "HR Declaration on Shareholders and Directorship is required",
      DEC_ENTITLEMENT: "Declaration of Entitlement to Claim Input Tax on Insurance Policy is required",
      DEC_SPECIFIC: "Declaration of Specific Individual is required",
      ACRA: "ACRA Document is required",
      MAS314: "MAS314 is required"
    };

    // getRenewalType
    if (!this.isValid().valid) {
      this.isValid().missingForms.map(type => {
        let messageType = this.getPrefix(renewalType) + type;
        this.setUploadFormErrors({
          [messageType]: {
            dialogDescription: errorMessages[type]
          }
        });
      });
    }
  }

  isValid(isChecked) {
    const { document, policyRenew } = this.props;
    const { errors, renewalType } = this.state;
    let fileTypes = [];
    document.renewalFiles.map(item => {
      fileTypes.push(item.file_type);
    });
    let mandatoryForms = [];
    if (policyRenew.has_hr_dec_changed || isChecked) {
      mandatoryForms = ["LETTER", "DEC_ENTITLEMENT", "DEC_SPECIFIC", "ACRA", "MAS314"];
    } else {
      mandatoryForms = ["LETTER", "HR_DEC", "DEC_ENTITLEMENT", "DEC_SPECIFIC"];
    }
    let missingForms = mandatoryForms.filter(item => !fileTypes.includes(`${this.getPrefix(renewalType)}${item}`));
    if (missingForms.length === 0) {
      return {
        valid: true
      };
    } else {
      return {
        valid: false,
        missingForms: missingForms
      };
    }
  }

  render() {
    const { classes, showLoadingPopup, navDrawerOpen, setHasHrDecChanged } = this.props;
    const { activeStep, selectedPolicy, renewalType, renewalTypeOptions, errors } = this.state;
    return (
      <div className={classes.root}>
        <LoadingPopup title="Loading..." open={showLoadingPopup} />
        <div className={classes.breadcrumb}>
          <CustomBreadcrumb currentPage={this.getBreadCrumbTitle()} />
        </div>
        <Typography className={classes.title}>{this.getPageTitle()}</Typography>

        <SimpleMessageDialog
          type="error"
          title="Error"
          description={this.state.errorMessage}
          closeHandler={this.handleCloseMessageDialog}
          open={this.state.openMessageDialog}
          closeButtonText="Close"
        />
        {activeStep == 1 && (
          <>
            <RenewalListingTable
              handleRadioChange={this.handleRadioChange}
              selectedPolicy={selectedPolicy}
              // handleErrorInput={this.handleErrorInput}
              renewals={this.state.renewals}
            />
            <RenewalSubmissionListingTable renewalSubmissions={this.state.renewalSubmissions} />
          </>
        )}

        {activeStep == 2 && (
          <RenewalSelectRequest
            renewalTypeOptions={renewalTypeOptions}
            renewalType={renewalType}
            handleRadioChange={this.handleRadioChange}
          />
        )}
        {activeStep == 3 && (
          <RenewalUploadForms
            documentActions={this.props.documentActions}
            files={this.props.document.renewalFiles}
            prefix={this.getPrefix(renewalType)}
            renewalType={renewalType}
            setHasHrDecChanged={setHasHrDecChanged}
            errors={errors}
            setUploadFormErrors={this.setUploadFormErrors.bind(this)}
            setIsSubmitBtnDisabled={this.setIsSubmitBtnDisabled}
            onRemoveFile={this.onRemoveFile}
          />
        )}
        {activeStep == 4 && <RenewalSubmission />}
        {activeStep == 5 && <RenewalSubmittedDocuments />}

        {activeStep != 4 && (
          <PolicyRenewalSteps
            activeStep={activeStep}
            navDrawerOpen={navDrawerOpen}
            isRenewalBtnDiabled={this.state.isRenewalBtnDiabled}
            isSubmitBtnDiabled={this.state.isSubmitBtnDiabled}
            handleNext={this.handleNext}
            handleSave={this.handleSave}
            handleSubmit={this.handleSubmit}
          />
        )}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    overflowX: "auto",
    padding: "0px 25px"
  },
  breadcrumb: {
    padding: "30px 0px 30px 0px"
  },
  title: {
    fontSize: "24px",
    fontWeight: "600 !important",
    padding: "10px 0px 20px 0px"
  },
  description: {
    fontSize: "16px",
    padding: "0px 0px 20px 0px"
  },
  font: {
    fontWeight: "600 !important"
  },
  searchButton: {
    height: "2.7rem",
    width: "8rem",
    backgroundColor: theme.colors.primary.red,
    color: theme.palette.common.white
  },
  searchInput: {
    width: "100%"
  }
});

function mapStateToProps(state) {
  return {
    renewalList: state.renewalList,
    renewalSubmissionList: state.renewalSubmissionList,
    showLoadingPopup: state.app.showLoadingPopup,
    renewalPolicies: state.renewPolicies,
    policyRenew: state.policyRenew,
    document: state.document
  };
}

function mapDispatchToProps(dispatch) {
  return {
    documentActions: bindActionCreators(documentActions, dispatch),
    ...bindActionCreators(renewalActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(Renewal)));
