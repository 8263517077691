import React, { Component, lazy } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { withStyles } from "@mui/styles";
import { Typography, Grid } from "@mui/material";
import Enums from "../../../../../utils/Enums";
import CategoryPackageTable from "./CategoryPackageTable";
import plansPTF from "../../../../../containers/Auth/components/Plans/index";
import plansPF3 from "../../../../../containers/Auth/components/Plans/indexPF3";
import moment from "moment";

class CoveragePackage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      plans: {
        selectedTypes: [Enums.PLANS_TYPE.BENEFITS]
      },
      categories: null,
      selectedTiers: null,
      extendedBenefits: null,
      infoPopover: {
        open: false,
        element: null,
        data: ""
      }
    };
  }

  componentWillMount() {
    const { policy } = this.props;
    const categories = policy.packagedPlans || [];
    const categoriesWithPlans = categories.filter(category => category.plans && category.plans.length > 0);
    const catPlans = categoriesWithPlans.map(category => category.plans);
    let selectedTiers = this.getSelectedTiers(categories);
    this.setState({ categories: categories, selectedTiers: selectedTiers, catPlans: catPlans });
  }

  getSelectedTiers = categories => {
    let selectedTiers = {};
    if (categories) {
      categories.forEach(c => {
        if (c.plans) {
          c.plans.forEach(p => {
            let productCode = p.productCode;
            if (productCode === "GOSP") {
              productCode = "PSIH";
            }
            if (productCode === "GPRU") {
              productCode = "PGIH";
            }
            if (!selectedTiers[productCode]) selectedTiers[productCode] = [];
            selectedTiers[productCode].push(parseInt(p.tier, 10));
          });
        }
      });
    }
    return selectedTiers;
  };

  getCoverageBenefits = policy => {
    let contractType = policy.contractType;
    let Plans;

    if (contractType === "PF2" || contractType === "PTF") {
      contractType = "PTF";
      Plans = plansPTF;
    } else {
      contractType = "PF3";
      Plans = plansPF3;
    }
    return { contractType, Plans };
  };

  formatDate = date => {
    return moment(date).format("DD MMM YYYY");
  };

  render() {
    const { classes, quote, products, policy } = this.props;
    const { plans, categories, selectedTiers, catPlans } = this.state;
    const { contractType, Plans } = this.getCoverageBenefits(policy);

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <Typography className={classes.title}>
            {policy.companyName && `${policy.companyName}'s`} Insurance Coverage
          </Typography>
          <div className={classes.root2}>
            <Grid container spacing={"0px"} className={classes.insuranceOverView}>
              <Grid item xs={12}>
                <div className={classes.contentWrapper}>
                  <div className={classes.rowWrapper}>
                    <Typography component="p" className={classes.label}>
                      Company Name
                    </Typography>
                    <Typography component="p">
                      <span className={classes.labelInfo}>{policy.companyName || "-"}</span>
                    </Typography>
                  </div>
                  <div className={classes.rowWrapper}>
                    <Typography component="p" className={classes.label}>
                      Policy Number
                    </Typography>
                    <Typography component="p">
                      <span className={classes.labelInfo}>{policy.policyNo || "-"}</span>
                    </Typography>
                  </div>
                  <div className={classes.rowWrapper}>
                    <Typography component="p" className={classes.label}>
                      Policy Period
                    </Typography>
                    <Typography component="p">
                      <span className={classes.labelInfo}>
                        {policy.policyStartDate && policy.policyEndDate
                          ? `${this.formatDate(policy.policyStartDate)} to ${this.formatDate(policy.policyEndDate)}`
                          : "-"}
                      </span>
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <CategoryPackageTable data={categories} config={products[quote.product_code].config} />
          <br />
          {!catPlans || catPlans.length === 0 ? null : (
            <Plans
              types={plans.selectedTypes}
              tiers={selectedTiers}
              selectionDisplayMode={"HIGHLIGHT"}
              portalType={"PI"}
              displayAll={true}
              resetTime={quote.resetTime}
              productCode={contractType}
              policyEffectiveDate={policy.policyStartDate}
            />
          )}
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    marginBottom: theme.actionFooter.height,
    fontSize: "0.75em"
  },
  content: {
    padding: theme.spacing.unit * 2.5
  },
  root2: {
    backgroundColor: "white",
    padding: theme.spacing.unit * 2.5,
    paddingTop: 10,
    padding: "1.5%"
  },
  insuranceOverView: {
    marginTop: 20
  },
  insuranceOverView: {
    marginTop: 20
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column"
  },
  rowWrapper: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "15px"
  },
  label: {
    fontWeight: "bold",
    width: "30%",
    display: "flex"
  },
  "@media (max-width: 600px)": {
    label: {
      width: "50%"
    }
  },
  labelInfo: {
    marginLeft: "0px"
  },
  title: {
    fontSize: "20px",
    fontWeight: "600 !important",
    paddingBottom: "5px",
    marginLeft: "10px",
    marginBottom: "20px"
  }
});

function mapStateToProps(state) {
  return {
    policy: state.policyDetails.policy,
    quote: state.quote,
    products: state.products
  };
}

export default connect(mapStateToProps)(injectIntl(withStyles(styles, { withTheme: true })(CoveragePackage)));
