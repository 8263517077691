/**
 * Check if user is running on IE
 * Then we will additional root style for fixing some issues
 * @ bowser.msie => True is user is using IE
 * @ https://github.com/lancedikson/bowser
 */
const ieCompatible = navigator.userAgent.indexOf("MSIE") > -1 || navigator.userAgent.indexOf("rv:") > -1
  ? {
      MuiInput: {
        root: {
          height: 31
        }
      },
      MuiDialogActions: {
        root: {
          justifyContent: "center"
        }
      }
    }
  : {};

export default ieCompatible;
